@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";


/* Reset CSS
   ========================================================================== */
   html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0; padding: 0; border: 0; font-size: 100%; vertical-align: baseline; }

   body { background: #f3f3f3; font-family: 'Poppins', sans-serif; color: #000000; font-size: 16px; line-height: 1; }

/* Common CSS
   ========================================================================== */
    ol, ul { list-style: none; }
    h2, h3, h4, h5{ color:  #000000;}
    *:focus, *:hover {outline: 0!important;}
    a, .btn { transition: all 0.5s ease; }
    a:active, a:hover, a:focus, .btn:focus, .btn:active, .btn:hover { outline: 0; }

    a { cursor: pointer; color: #0d4e94; text-decoration: none; }
    a:focus, a:hover { color: #0369d8; text-decoration: none; }

    b, strong { font-weight: bold; }
    small { font-size: 80%; }
    sub, sup { font-size: 72%; line-height: 0; position: relative; vertical-align: baseline; }
    sup { top: -0.4em; }
    sub { bottom: -0.25em; }

    img { border: 0; vertical-align: middle; max-width: 100%; height: auto;}

    hr { box-sizing: content-box; height: 0; }

    *:focus { outline: 0!important; }

    svg:not(:root) { overflow: hidden; }

    a:hover .iconsvg, a .iconsvg-hover,a.active .iconsvg, li.active a .iconsvg{ display: none; }
    a:hover .iconsvg-hover,a.active .iconsvg-hover, li.active a .iconsvg-hover{ display: inline-block;}

    /*-------------------------------------------------------*/
    /*  Common Css
    /*-------------------------------------------------------*/
    .btn{ border-radius: 3px; font-size: 15px; font-weight: 600; padding: .62rem 1rem;}
    .btn.focus, .btn:focus{ box-shadow: none!important;}

    .btn-sm { font-size: 13px; padding: .3rem .6rem; }

    .btn-primary{ background: #0d4e94; border-color: #0d4e94;  color: #ffffff!important; }
    .btn-primary:hover, .btn-primary:focus{ background: #007BC2; border-color: #007BC2;color: #ffffff!important; }

    .btn-red{ background: #fc434e; border-color: #fc434e;  color: #ffffff!important; }
    .btn-red:hover, .btn-red:focus{ background: #007BC2; border-color: #007BC2;color: #ffffff!important; }

    .btn-border{ background:transparent; border-color: #0d4e94;  color: #0d4e94; }
    .btn-border:hover, .btn-border:focus{ background: #0d4e94; border-color: #0d4e94;color: #ffffff; }



    /*-------------------------------------------------------*/
/*  Badge and color Css
/*-------------------------------------------------------*/
.badge-primary, .bg-primary{ background-color: #0d4e94!important; }
.badge-blue, .bg-blue{ color: #fff!important; background-color: #6666FF!important;}
.badge.Verified, .badge.Open, .badge-success, .bg-success{ color: #fff!important; background-color: #1dc9b7!important;}
.badge-warning, .bg-warning { color: #ffffff!important; background-color: #ffb822!important; }
.badge.Non-Verified, .badge.Close, .badge-danger, .bg-danger { color: #fff!important; background-color: #eb5261!important; }
.badge-gray, .bg-gray { color: #111!important; background-color: #e9ecef!important; }

.text-default, .text-primary { color: #0d4e94!important;}
.text-success{ color: #1dc9b7!important; }
.text-warning { color: #ffb822!important; }
.text-danger { color: #eb5261!important; }
.text-disable { color: #999999!important; }
.text-blue { color: #0d4e94!important; }
.text-gray-400 { color: #d1d3e2!important; }
.text-red { color: #fc434e!important; }

.bg-blue-light{ color: #6666FF!important; background-color: rgba(102, 102, 255, 0.2)!important;}
.bg-success-light{ color: #1dc9b7!important; background-color: rgba(29, 201, 183, 0.2)!important;}
.bg-warning-light { color: #ffb822!important; background-color: rgba(255, 184, 34, 0.2)!important; }
.bg-primary-light{ color: #0d4e94!important; background-color: rgba(13, 78, 148, 0.2)!important; }

.loginWrapper { background-image: radial-gradient(circle at 66%, #EC1C24, #883356, #0F4E93); width: 100%; position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 99; outline: 0; overflow-x: hidden; overflow-y: auto; }
.loginContent { display: -webkit-flex; display: flex; align-items: center; justify-content: center; margin: 1.75rem auto; min-height: 100%; padding: 15px; }
.loginContent .loginBox { max-width: 500px; width: 100%; margin: auto; padding: 30px; background-color: #ffff; }
.loginContent .logo { text-align: center; margin-bottom: 30px; }
.loginContent .logo img { width: 180px; }
.loginContent .title { text-align: center; color: #0d4e94; font-size: 1.8rem; text-transform: uppercase; margin-bottom: 1.2rem; }
.loginContent img.loder_img_btn { width: 40px; margin: 0px 8px; height: auto; }

.dropdown .dropdown-menu { font-size: .85rem; }
.textLink {cursor: pointer; color: #0d4e94; font-weight: 500; }
.relative{ position: relative;}

.textGray { color: #575656; }

.block { display: block; }
.hide { display: none; }

    .badge { font-weight: 500; line-height: 1.3; }
    .pr-9{ padding-right: 9rem!important;}
    .font-300{font-weight: 300!important;}
    .font-500{font-weight: 500!important;}
    .font-600{font-weight: 600!important;}

  .word-break { white-space: normal!important; word-break: break-all!important; }
  .word-wrap { white-space: normal!important; }
  .text-wrap, .word-wrap { white-space: normal!important; }
  .fas.fa-phone { transform: rotate(98deg); }

  .pointer{ cursor: pointer;}

    /*-------------------------------------------------------*/
    /*  Element Css
    /*-------------------------------------------------------*/

    .row-custom { margin-left: -7.5px; margin-right: -7.5px; }
    .row-custom [class*=col-], .row-custom [class*=col]  { padding: 7.5px; }

    .boxshadow{ background: #ffffff;  border: 1px solid #ebebeb; box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01); -webkit-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01); padding: 15px;}

    .sticky, sticky {width: 100%!important;}
    .wrapper .content { padding-top: 15px;min-height: calc(100vh - 150px); }


/*-------------------------------------------------------*/
/* Dropdown Css
/*-------------------------------------------------------*/
.dropdown li { border-bottom: 1px solid #e9ecef; text-align: left; }
.dropdown li a { display: block; width: 100%; padding: 7px 10px; font-weight: 400; white-space: nowrap; font-size: 15px; }
.dropdown li a i { margin-right: 5px; }
.dropdown li:first-child a { padding-top: 0px; }
.dropdown li:last-child {border-bottom: 0;}
.dropdown li:last-child a { padding-bottom: 0; }

/*-------------------------------------------------------*/
/* Table Css
/*-------------------------------------------------------*/
.table-default { min-width: 767px;border-collapse: separate; border-spacing: 0 9px; }
.table-default th, .table-default td { font-weight: 500; vertical-align: middle; white-space: nowrap; }
.table-default thead th { vertical-align: middle; border: 0; background: #0d4e94; color: #fff; padding: 0.9rem 10px; border-bottom: 6px solid #f3f3f3; font-size: 15px; text-transform: capitalize; }

.table-default  tbody td{ background: #fff; border: 0; padding: 10px 10px; min-height: 60px; border-top: 1px solid #ebebeb; border-bottom: 1px solid #ebebeb;/* color: #787777;*/ font-size: 15px; line-height: 1.2rem;}
.table-default tbody td .link{ font-weight: 600}
.table-default tbody td .btn-icon { padding: .063rem 0.2rem; }

.table-center { text-align: center; }
.table-default tbody td.textGray { color: #575656; }

.table-hover tbody tr:hover td{ color: #656565; background-color: #f6f6f6; }

.table-default .dividerLine{padding: 10px!important;}
.table-default .dividerLine .divider { background: #00d6be; height: 39px; width: 4px; display: block; }

.table-sticky{ max-height: 500px; overflow: auto; }
.table-sticky table thead tr:nth-child(1) th{ position: sticky; top: 0; z-index: 1; }

.table-default tbody td.nofoundTxt, .nofoundTxt{text-align: center; color: #9c9c9c; font-size: 1rem; padding: .9rem 10px; vertical-align: middle;}

.modal-body .table-default thead th {
  border-bottom-color: #fff;
}
/*-------------------------------------------------------*/
/*  Element Css
/*-------------------------------------------------------*/
.wrapper { padding-top: 64px;}
.main-wrapper { display: -webkit-flex; display: -ms-flexbox; display: flex; }
.main-wrapper #content-wrapper { width: 100%; overflow-x: hidden;}
.main-wrapper #content-wrapper #content { padding:10px 20px 15px; -webkit-box-flex: 1; -ms-flex: 1 0 auto; flex: 1 0 auto; }

.main-wrapper.closemenu #content-wrapper{ margin-left: 60px;  width: calc(100% - 60px); padding-bottom: 0px; position: relative; min-height: calc(100vh - 44px);}
.main-wrapper #content-wrapper{ margin-left: 180px;  width: calc(100% - 180px); padding-bottom:0px; position: relative; min-height: calc(100vh - 81px); }

.row-custom { margin-left: -7.5px; margin-right: -7.5px; }
.row-custom [class*=col-], .row-custom [class*=col]  { padding: 7.5px; }

.boxshadow{ background: #ffffff;  border: 1px solid #ebebeb; box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01); -webkit-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01); padding: 15px;}

.wrapper.nonlogin { padding: 0; }
.wrapper.nonlogin #content-wrapper { margin: 0; width: 100%; min-height: 100vh; }
.wrapper.nonlogin .sidebar{ display: none;}
.wrapper.nonlogin .main-wrapper #content-wrapper #content {
  padding: 0;
}
/*-------------------------------------------------------*/
/*  Sidebar Css
/*-------------------------------------------------------*/
.sidebar { background: #0d4e94;   height: calc(100% - 44px); top: 62px; min-width: 180px; position: fixed; z-index: 8 !important; transition: all .2s ease; }
.sidebar .sidebar-brand .sidebar-brand-icon { display: none; }

.nav-sidebar .btn-bars {  background: #094381; font-size: 24px; float: right; color: #fff; height: 57px; line-height: 1; width: 57px; margin-bottom: 10px; }

.sidebar .sidebar-inner{ padding-top: 55px; height: 100%; overflow: hidden; }
.sidebar .sidebar-scroll{ height: 100%; padding-bottom: 20px; overflow-x: hidden; margin-right: -10px; overflow-y: scroll; }
.sidebar .nav-togglebtn{ position: absolute!important; top: 0px; width: 100%; }

.sidebar .nav-sidebar > li.nav-item { padding-left: 5px; position: relative;  }
.sidebar .nav-sidebar > li.nav-item a{ display: -webkit-box; display: -ms-flexbox; display: flex; border-radius: 40px 0px 0px 40px; position: relative; display: flex; align-items: center; padding: 1rem 1.25rem; outline-width: 0; transition: none; color: #ffffff; font-size: 13px; height: 64px; }
.sidebar .nav-sidebar > li.nav-item .icon { width: 25px; margin-right: 15px; }
.sidebar .nav-sidebar > li.nav-item a:hover, .sidebar .nav-sidebar > li.nav-item a.active,  .sidebar .nav-sidebar > li.nav-item.active a,   .sidebar .nav-sidebar > li.nav-item.active a:hover, .sidebar .nav-sidebar > li.nav-item a.active:hover { background: #083d76; color: #fff; }

.sidebar.toggled { background: transparent; overflow: visible; min-width: 60px; width: 60px; flex-basis: auto; }

.sidebar.toggled .nav-sidebar li.nav-item:first-child { padding: 0;}
.sidebar.toggled .nav-sidebar li.nav-item { position: relative; padding: 0px 5px; z-index: 1; }
.sidebar.toggled .nav-sidebar li .btn-bars { width: 100%; }

.sidebar.toggled .nav-sidebar > li.nav-item a > span { display: none; }
.sidebar.toggled .nav-sidebar > li.nav-item a { border-radius:100%; padding: 0.5rem; width: 50px; height: 50px; margin: 0; }
.sidebar.toggled .nav-sidebar > li.nav-item a .icon { margin: auto;}
.sidebar.toggled .nav-sidebar > li.nav-item.active a:after, .sidebar.toggled .nav-sidebar > li.nav-item a.active:after {content: "";display: block;background: #ffd325;width: 7px;height: 7px;border-radius: 100%;position: absolute; bottom: 3px; left: 0; margin: auto;right: 0; margin-left: 22px; }

.sidebarHover .sidebar-scroll { margin-right: -24px; }

.sidebar.sidebarHover{  width: auto;}
.sidebarHover.toggled .nav-sidebar li.nav-item { background: #0d4e94; }

.sidebarHover.toggled .nav-sidebar li.nav-item { background: #0d4e94; width: 60px; }
.sidebarHover.toggled .nav-sidebar > li.nav-item:hover a > span { display: inline-block; white-space: nowrap; padding-right: 20px; }

.sidebarHover.toggled .nav-sidebar li.nav-item:hover { width: auto; padding-right: 0; }

.sidebarHover.toggled .nav-sidebar li.nav-togglebtn:hover { width: 60px; }

.sidebarHover.toggled .nav-sidebar li.nav-item:hover a {background: #083d76;width: auto;border-radius: 40px 0px 0px 40px;}
.sidebarHover.toggled .nav-sidebar > li.nav-item:hover a .icon { margin-right: 8px; margin-left: 4.2px; }

.sidebar.toggled.sidebarHover:after { content: ""; background: #0d4e94; width: 60px; position: absolute; bottom: 0; height: 100%; }

@media (max-width: 640px){
  .sidebar{ top: 68px;}
}

/*-------------------------------------------------------*/
/*  Content Css
/*-------------------------------------------------------*/
.reportContent { position: relative; }
.maintitle{ width: 100%; padding-right: 130px; }
.maintitle.minh-44{ min-height: 44px;}
.maintitle h1{ font-size: 1.2rem; font-weight: 500; }

.addIcon span.icon, .addReport a, .add-btn, .csv-btn { width: 44px; height: 44px; background: #0d4e94; display: block; border-radius: 100%; padding: 8px; line-height: 15px; display: inline-block; vertical-align: top; }
.csv-btn{ background: #f6f6f6; border: 1px solid #c5c5c5;}

.mat-tab-group { font-family: 'Poppins', sans-serif; }
.reportContent .mat-tab-header, .reportContent .nav-tabs { position: absolute!important; right: 0; top: 0; }
.reportContent .mat-tab-label, .reportContent .nav-tabs .nav-item a.nav-link{
background: #fff;
border-radius: 5px;
opacity: 1!important;
width: 44px;
padding: 0 !important;
height: 44px !important;
min-width: 44px !important;
margin-left: 20px;
}
.reportContent .matTabs img.mattabIcon,  .reportContent .nav-tabs .nav-item img.mattabIcon{width: 26px !important;}
.reportContent .mat-tab-header, .mat-tab-nav-bar{border:0 !important;}
.reportContent .mat-ink-bar{display: none;}

.reportContent .mat-tab-body-content{ overflow: hidden; }
.reportContent .nav-tabs .nav-item {
margin: 0;
border: 0;
}

.reportContent .nav-tabs {
border: 0;
align-items: center;
}

.form-label {     display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;}

.reportContent .nav-tabs .nav-item a.nav-link {
padding: 8px!important;
}
.reportContent .nav-tabs .nav-item {
margin: 0;
border: 0;
}

.reportContent .nav-tabs {
border: 0;
align-items: center;
}

.reportContent .nav-tabs .nav-item a.nav-link {
padding: 8px!important;
}
.reportContent .mat-tab-label-active, .reportContent .nav-tabs .nav-item a.nav-link:hover, .reportContent .nav-tabs .nav-item a.nav-link.active {
background: #cce5ff !important;
color: #fff !important;
border: 1px solid #015c67 !important;
color: #0d4e94 !important;
}

@media (max-width: 990px){
  .maintitle { padding-right: 105px; }
  .reportContent .mat-tab-label, .reportContent .nav-tabs .nav-item a.nav-link{ margin-left: 8px;}
}

/*-------------------------------------------------------*/
/*  Report Tab Box  Css
/*-------------------------------------------------------*/
.reportTab ul { display: flex; display: -webkit-flex; flex-wrap: wrap; justify-content: space-around; margin-left: -7.5px; margin-right: -7.5px; }

.reportTab ul li { flex: 1 1 auto; padding: 6px; }
.reportTab ul li .reportTabBox .icon {  width: 50px; height: 50px; border-radius: 100%; padding: 0px; margin-right: 10px;justify-content: center;
  align-items: center;
  display: flex; }
  .reportTab ul li .reportTabBox .icon img {
    max-width: 28px;
}
.reportTab ul li .reportTabBox { background: #fff; border: 1px solid #ebebeb; border-radius: 3px; cursor: pointer; display: flex; display: -webkit-flex; flex-wrap: wrap; padding: 15px; }
.reportTab ul li .reportTabBox .left{ display: flex; display: -webkit-flex;  align-items: center; }
.reportTab ul li .reportTabBox .number { margin-left: auto; font-weight: 600; font-size: 1.8rem; min-width: 65px; text-align: right; }
.reportTab ul li .reportTabBox  h3 { font-weight: 500; font-size: 1.35rem; }

.reportStaffTab ul { justify-content: center; }
.reportStaffTab .reportTabItem { flex-basis: 20%; min-width: 20%; display: flex; }
.reportStaffTab .reportTabItem h5 { font-weight: 500; text-transform: uppercase; font-size: 15px; margin-bottom: 7px; }
.reportStaffTab .reportTabItem .id { font-weight: 600; font-size: 16px; width: 100%; line-height: 1rem; word-break: break-word; white-space: normal; }
.reportStaffTab .reportTabItem .reportTabBox { width: 100%; flex-wrap: nowrap; }
.reportStaffTab ul li .reportTabBox .icon{ min-width: 50px;}
.reportStaffTab ul li .reportTabBox .icon img { height: 28px; width: 28px; }
.reportStaffTab ul li .reportTabBox .icon i{ color: #fff; font-size: 30px;}
.reportTab ul li .reportTabBox.purpleColor.active {
border: 1px solid #3d29af;
box-shadow: 0px 2px 8px rgba(69, 45, 177, 0.23);
-webkit-box-shadow: 0px 2px 8px rgba(69, 45, 177, 0.23);
}

.reportTab ul li .reportTabBox.redshadeColor.active {
border: 1px solid #ff7e9a;
box-shadow: 0px 2px 8px rgba(255, 126, 154, 0.23);
-webkit-box-shadow: 0px 2px 8px rgba(255, 126, 154, 0.23);
}

.reportTab ul li .reportTabBox.blueshadeColor.active {
border: 1px solid #6a7af4;
box-shadow: 0px 2px 8px rgba(106, 122, 244, 0.23);
-webkit-box-shadow: 0px 2px 8px rgba(106, 122, 244, 0.23);
}

.reportTab ul li .reportTabBox.greenColor.active {
border: 1px solid #04b5a7;
box-shadow: 0px 2px 8px rgba(4, 181, 167, 0.23);
-webkit-box-shadow: 0px 2px 8px rgba(4, 181, 167, 0.23);
}
@media (max-width: 1230px) {
  .reportTab ul li .reportTabBox {
    padding: 10px;
}
}
@media (max-width: 1200px) {.reportStaffTab ul { justify-content: flex-start;} .reportStaffTab .reportTabItem { flex-basis: 25%; min-width: 25%; max-width: 25%; } }
@media (max-width: 1024px) {.reportStaffTab .reportTabItem { flex-basis: 33.33%; min-width: 33.33%; max-width: 33.33%; } }
@media (max-width: 767px) {.reportStaffTab .reportTabItem { flex-basis: 50%; min-width: 50%; max-width: 50%; } }
@media (max-width: 640px) {.reportStaffTab .reportTabItem { flex-basis: 100%; min-width: 100%; max-width: 100%; } }



/*-------------------------------------------------------*/
/*  Card Box List Css
/*-------------------------------------------------------*/
.cardBox { display: flex; display: -webkit-flex; flex-wrap: wrap; margin-left: -7.5px; margin-right: -7.5px; }
.cardBoxList { display: flex; display: -webkit-flex; padding: 7.5px; width: 10%; }

@media (max-width: 5400px) {
  .cardBoxList { width: 14.28%; }
  .candidateList.open_mob_tab .cardBoxList {width: 12.5%; }
}
@media (max-width: 3400px) {
  .cardBoxList { width: 16.66%; }
  .candidateList.open_mob_tab .cardBoxList {width: 14.28%; }
}
@media (max-width: 2800px) {
  .cardBoxList { width: 20%; }
  .candidateList.open_mob_tab .cardBoxList {width: 16.66%; }
}
@media (max-width: 1800px) {
  .cardBoxList { width: 25%; }
  .candidateList.open_mob_tab .cardBoxList {width: 20%; }
}
@media (max-width: 1200px) {
  .cardBoxList { width: 33.33%; }
  .candidateList.open_mob_tab .cardBoxList {width: 25%; }
}
@media (max-width: 990px) {
  .cardBoxList { width: 50%; }
  .candidateList.open_mob_tab .cardBoxList {width: 33.33%; }
}
@media (max-width: 560px) {
  .cardBoxList { width: 100%; }
}

.cardContent { background: #ffffff; border: 1px solid #ebebeb; box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01); -webkit-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01); display: flex; flex-direction: column; width: 100%; padding: 15px;margin-bottom: 0px;  font-size: 14px; line-height: 1.2rem; color: #000; text-align: center; position:relative;}

.cardBox .cardAdd { text-align: center; }
.cardBox .cardAdd .addIcon { background: #e6edf4; width: 76px; height: 76px; display: block; border-radius: 100%; position: relative; margin-bottom: 20px; }
.cardBox .cardAdd .addIcon:after,.cardBox .cardAdd .addIcon:before {content: "";background: #0d4e94;width: 4px;height: 45px;display: block;position: absolute;left: 0;right: 0;margin: auto;top: 0;bottom: 0;}
.cardBox .cardAdd .addIcon:before { width: 45px; height: 4px; }
.cardBox .cardAdd .label { display: block; text-transform: uppercase; font-size: 1.1rem; }
.cardBox .cardAdd a { display: flex; display: -webkit-flex; flex-direction: column; align-items: center; color: #000; height: 100%; justify-content: center; }
.cardBox .cardAdd a:hover{color: #0d4e94;}
.cardBox .cardAdd a:hover .addIcon{ background: #0d4e94;}
.cardBox .cardAdd a:hover .addIcon:after,.cardBox .cardAdd a:hover .addIcon:before{background: #fff;}

.cardBox .txtDesc{ line-height: 1.5rem;}
.cardBox .cardContent .linkTxt { cursor: pointer; font-size: 15px; font-weight: 500; }
.cardBox .cardContent .link{ cursor: pointer; color: #0d4e94; font-weight: 500;}
.cardBox .cardContent .date { color: #575656; }
.cardBox .cardContent .name, .cardBox .cardContent .name-p2{ line-height: 1.4rem; }
.star{ font-size: 10px; vertical-align: baseline; color: #FFC107; }
.cardBox .cardContent .name .star { margin-right: 3px; font-size: 10px; vertical-align: baseline; color: #FFC107; }
.cardBox .cardContent .name .star i.disable { color: #e6e6e6; }

.cardBox .cardContent .name-p2{ padding: 0 2.2rem; }
.cardBox .textRow { border-top: 1px solid #e1e1e1; border-bottom: 1px solid #e1e1e1; display: flex; display: -webkit-flex; flex-wrap: wrap; margin: 10px 0; }

.cardBox .textRow .txtcol { flex-basis: 50%;min-width: 50%; position: relative; text-align: center; padding: 10px 0; }
.cardBox .textRow .txtcol:after {content: "";background: #e1e1e1;height: calc(100% - 15px);top: 0;bottom: 0;margin: auto;width: 1px;right: 0;position: absolute;}
.cardBox .textRow .txtcol:last-child:after { display: none; }

.cardBox .textRow .txtcol label { font-weight: 500; color: #909090; font-size: 14px; display: block; text-align: center; }
.cardBox .textRow .txtcol span { color: #0d4e94; font-size: 13px;    line-height: 1rem; font-weight: 500; }

.cardBox .cardContent .companylogo { font-weight: 400; font-size: 13px; }
.cardBox .cardContent .companylogo img{ height: 18px; width: auto; margin-right: 4px; max-width: 100%;}

.cardBox .cardContent .text{ line-height: 1.1rem; }

.cardBoxList.nofoundTxt{ width: 100%!important;}
.cardBoxList.nofoundTxt .cardContent{ display: block; color: #8c8c8c;}
.cardBoxList.nofoundTxt i { font-size: 24px; color: #bdbdbd; vertical-align: middle;}

.detailList { padding: 15px 0; display: flex; display: -webkit-flex; align-items: flex-end; }
.detailList .detailCol { padding-right: 5px; }
.detailList .detailCol ul li { text-align: left; list-style: none; margin-bottom: 5px; font-size: 12px; display: flex; display: -webkit-flex; flex-wrap: wrap; }
.detailList .detailCol ul li label { width: 68px; min-width: 68px; font-weight: 600; margin-bottom: 5px; margin-right: 5px; }
.detailList .detailCol ul li .wordBreak { word-break: break-all; }

.detailList .applicants { font-size: 12px; border-left: 1px solid #e1e1e1; padding: 5px 0 5px 5px;  margin-left: auto;}
.detailList .applicants .number { font-weight: 600; font-size: 2rem; margin-bottom: 5px; }
.detailList .detailCol ul li:last-child { margin-bottom: 0; }

.cardBox .cardContent .bottom{ margin-top: auto;}

.cardBox .blue-divider { background: #0d4e94; height: 4px; margin: 12px 0; }
.cardBox .dividerLine { background:  #e1e1e1; height: 1px; margin: 10px 0; }
.cardBox .cardContent .bottom .btn-sm { font-size: 12px; padding: 6px; }
.checkicon, .cardBox .adduser { position: absolute; right: 10px; width: 34px; height: 34px; border-radius: 100%; color: #fff; top: 10px; line-height: 3rem; }
.cardBox .adduser { right: auto; left: 10px; line-height: 1; width: auto; height: auto; }
.cardBox .adduser a, .cardBox .adduser .link { background: #0d4e94; width: 34px; height: 34px; border-radius: 100%; display: block; padding: 8px; color: #fff; text-align: center; }
.cardBox .adduser .close{ font-size: 15px; margin-top: 4px;}
.cardBox .adduser a i, .cardBox .adduser .link i { margin-top: 1px;}
.cardBox .adduser a:hover, .cardBox .adduser .link:hover { background: #0369d8;}
.cardBox .adduser.right{ left: auto; right: 10px;}

.table-default thead th.td-mat-select { padding: 0rem 10px; }
.table-default thead .td-mat-select .mat-form-field-wrapper {padding-bottom: 0;}
.table-default thead .td-mat-select .mat-form-field-wrapper .mat-select-value-text { color: #fff; font-size: 15px; font-weight: 500; }
.table-default thead .td-mat-select .mat-form-field-wrapper .mat-select-arrow-wrapper .mat-select-arrow { color: #fff; }

.table-default thead .td-mat-select .mat-form-field-appearance-legacy .mat-form-field-underline { display: none; background: #fff;bottom: 0px;}
.table-default thead .td-mat-select .mat-form-field-appearance-legacy .mat-form-field-infix { padding: 0; border-top: 0px; }
.table-default thead .td-mat-select .mat-select-trigger { height: 30px!important; display: block; }

.table-default thead .td-mat-select .mat-form-field-label-wrapper .mat-form-field-label { color: #fff; }
.checkicon .mat-checkbox .mat-checkbox-layout {
background: #e6e6e6;
width: 34px;
height: 34px;
display: block;
border-radius: 0;
line-height: 1;
}

.cardBox .topRightpos {
  position: absolute;
  right: 10px;
  top: 10px;
}
.cardBox .topRightpos.badge{ top: 15px;}

.checkicon .mat-checkbox-checked .mat-checkbox-layout { background: #0d4e94;}
.checkicon .mat-checkbox .mat-checkbox-layout .mat-checkbox-background { background: transparent; }
 .checkicon .mat-checkbox .mat-checkbox-layout .mat-checkbox-frame { opacity: 0; }
 .checkicon .mat-checkbox .mat-checkbox-layout,  .checkicon .mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container,  .checkicon .mat-checkbox .mat-checkbox-layout .mat-checkbox-background-label {width: 18px; height: 18px; }
/*.cardBox .checkicon .mat-checkbox .mat-checkbox-layout .mat-checkbox-background .mat-checkbox-checkmark { width: 27px; margin-top: 4px; margin-left: 3px; opacity: 1; }*/
 .checkicon .mat-checkbox .mat-checkbox-layout .mat-checkbox-background .mat-checkbox-checkmark .mat-checkbox-checkmark-path { stroke-dashoffset: 0;}
 .checkicon .mat-checkbox .mat-checkbox-layout .mat-checkbox-background .mat-checkbox-checkmark { width: 15px; margin-top: -1px; margin-left: 1px; opacity: 1; }

 .checkicon  {
  position: relative;
  display: inline-block;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
  margin-right: 8px;
  vertical-align: middle;
}

.mat-paginator {
margin-top: 15px;
}

.truncate-inline-overflow{ display: inline-block;
width: auto;
max-width: 100%;
max-height: 20px;
word-break: break-all;
overflow: hidden;
position: relative;
padding-right: 10px; }

.truncate-overflow { display: block; width: 100%; max-height: calc(14px * 3); word-break: break-all; overflow: hidden; position: relative; padding-right: 9px; }
.truncate-overflow:before, .truncate-inline-overflow:before { bottom: 0; content: "..."; position: absolute; right: 0; }
.truncate-overflow:after, .truncate-inline-overflow:after { content: ""; position: absolute; right: 0; width: 10px; height: 1rem; background: white; }

.card {
  padding: 15px;
  margin-bottom: 15px;
}
/*-------------------------------------------------------*/
/*  Gradient Color  Css
/*-------------------------------------------------------*/
.purpleColorBg {
    background: rgb(54,38,173);
    background: -moz-linear-gradient(left,  rgba(54,38,173,1) 0%, rgba(174,96,208,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(54,38,173,1) 0%,rgba(174,96,208,1) 100%);
    background: linear-gradient(to right,  rgba(54,38,173,1) 0%,rgba(174,96,208,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3626ad', endColorstr='#ae60d0',GradientType=1 );
    }
    .redshadeColorBg{background: rgb(255,126,154);
    background: -moz-linear-gradient(left,  rgba(255,126,154,1) 0%, rgba(254,184,122,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(255,126,154,1) 0%,rgba(254,184,122,1) 100%);
    background: linear-gradient(to right,  rgba(255,126,154,1) 0%,rgba(254,184,122,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7e9a', endColorstr='#feb87a',GradientType=1 );
    }
    .blueshadeColorBg{background: rgb(106,122,244);
    background: -moz-linear-gradient(left,  rgba(106,122,244,1) 0%, rgba(90,214,250,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(106,122,244,1) 0%,rgba(90,214,250,1) 100%);
    background: linear-gradient(to right,  rgba(106,122,244,1) 0%,rgba(90,214,250,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6a7af4', endColorstr='#5ad6fa',GradientType=1 );
    }

    .greenColorBg{background: rgb(2,186,132);
    background: -moz-linear-gradient(left,  rgba(2,186,132,1) 0%, rgba(4,181,167,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(2,186,132,1) 0%,rgba(4,181,167,1) 100%);
    background: linear-gradient(to right,  rgba(2,186,132,1) 0%,rgba(4,181,167,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02ba84', endColorstr='#04b5a7',GradientType=1 );
    }

    .yellowColorBg{
        background: rgb(255,188,48);
        background: -moz-linear-gradient(left,  rgba(255,188,48,1) 0%, rgba(255,221,38,1) 100%);
        background: -webkit-linear-gradient(left,  rgba(255,188,48,1) 0%,rgba(255,221,38,1) 100%);
        background: linear-gradient(to right,  rgba(255,188,48,1) 0%,rgba(255,221,38,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffbc30', endColorstr='#ffdd26',GradientType=1 );
    }

    .skygradient{background: rgb(54,38,173);
    background: -moz-linear-gradient(left,  rgba(54,38,173,1) 0%, rgba(174,96,208,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(54,38,173,1) 0%,rgba(174,96,208,1) 100%);
    background: linear-gradient(to right,  rgba(54,38,173,1) 0%,rgba(174,96,208,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3626ad', endColorstr='#ae60d0',GradientType=1 );
    }
    .lightredgradient{
        background: rgb(255,126,154);
        background: -moz-linear-gradient(left,  rgba(255,126,154,1) 0%, rgba(254,184,122,1) 100%);
        background: -webkit-linear-gradient(left,  rgba(255,126,154,1) 0%,rgba(254,184,122,1) 100%);
        background: linear-gradient(to right,  rgba(255,126,154,1) 0%,rgba(254,184,122,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7e9a', endColorstr='#feb87a',GradientType=1 );
    }
    .lightBluegradient{
        background: rgb(106,122,244);
        background: -moz-linear-gradient(left,  rgba(106,122,244,1) 0%, rgba(90,214,250,1) 100%);
        background: -webkit-linear-gradient(left,  rgba(106,122,244,1) 0%,rgba(90,214,250,1) 100%);
        background: linear-gradient(to right,  rgba(106,122,244,1) 0%,rgba(90,214,250,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6a7af4', endColorstr='#5ad6fa',GradientType=1 );
    }


/*-------------------------------------------------------*/
/* Ribbon Css
/*-------------------------------------------------------*/
.ribbon { position: absolute; right: -5px; top: -5px; z-index: 1; overflow: hidden; width: 75px; height: 75px; text-align: right; }
.ribbon span { font-size: 8px; font-weight: bold; color: #FFF; text-transform: uppercase; text-align: center; line-height: 16px; transform: rotate(45deg); -webkit-transform: rotate(45deg); width: 90px; display: block; background: #f58993; position: absolute; top: 19px; right: -21px; }
.ribbon span::before { content: ""; position: absolute; left: 0px; top: 100%; z-index: -1; border-left: 3px solid #f58993; border-right: 3px solid transparent; border-bottom: 3px solid transparent; border-top: 3px solid #f58993; }
.ribbon span::after { content: ""; position: absolute; right: 0px; top: 100%; z-index: -1; border-left: 3px solid transparent; border-right: 3px solid #f58993; border-bottom: 3px solid transparent; border-top: 3px solid #f58993; }

.ribbon.Open span, .ribbon.open span, .ribbon.verified span, .ribbon.Verified span{ font-size: 8px; font-weight: bold; color: #FFF; text-transform: uppercase; text-align: center; line-height: 16px; transform: rotate(45deg); -webkit-transform: rotate(45deg); width: 100px; display: block; background: #02ba84; background: linear-gradient(to right,  #02ba84 0%,#04b5a7 100%); position: absolute; top: 19px; right: -21px; }
.ribbon.Open span::before,.ribbon.open span::before,.ribbon.verified span::before, .ribbon.Verified span::before { content: ""; position: absolute; left: 0px; top: 100%; z-index: -1; border-left: 3px solid #02ba84; border-right: 3px solid transparent; border-bottom: 3px solid transparent; border-top: 3px solid #02ba84; }
.ribbon.Open span::after,.ribbon.open span::after,.ribbon.verified span::after, .ribbon.Verified span::after { content: ""; position: absolute; right: 0px; top: 100%; z-index: -1; border-left: 3px solid transparent; border-right: 3px solid #02ba84; border-bottom: 3px solid transparent; border-top: 3px solid #02ba84; }

.ribbon.proposed span, .ribbon.Proposed span, .ribbon.submitted span, .ribbon.Submitted span { font-size: 8px; font-weight: bold; color: #FFF; text-transform: uppercase; text-align: center; line-height: 16px; transform: rotate(45deg); -webkit-transform: rotate(45deg); width: 100px; display: block; background: rgb(106,122,244); background: linear-gradient(to right,  rgba(106,122,244,1) 0%,rgba(90,214,250,1) 100%); position: absolute; top: 19px; right: -21px; }
.ribbon.proposed span::before, .ribbon.Proposed span::before, .ribbon.submitted span::before,.ribbon.Submitted span::before { content: ""; position: absolute; left: 0px; top: 100%; z-index: -1; border-left: 3px solid rgb(106,122,244); border-right: 3px solid transparent; border-bottom: 3px solid transparent; border-top: 3px solid rgb(106,122,244); }
.ribbon.proposed span::after, .ribbon.Proposed span::after, .ribbon.submitted span::after,.ribbon.Submitted span::after { content: ""; position: absolute; right: 0px; top: 100%; z-index: -1; border-left: 3px solid transparent; border-right: 3px solid rgb(106,122,244); border-bottom: 3px solid transparent; border-top: 3px solid rgb(106,122,244); }

.badge.Proposed {
  color: #fff;
  background: rgb(106,122,244);
  background: linear-gradient(to right, rgba(106,122,244,1) 0%,rgba(90,214,250,1) 100%);
}

/*-------------------------------------------------------*/
/* Custom Modal Css
/*-------------------------------------------------------*/
.customModal .modal { display: block; overflow: auto; z-index: 999;}
.customModal .modal .modal-dialog {max-width: 700px; min-height: 100%; display: flex; display: -webkit-flex; align-items: center; justify-content: center; margin: auto; padding: 1.75rem 0; z-index: 1042; }
.customModal .modal .modal-dialog .modal-content { border-radius: 0px; border: 0; }
.customModal .modal-dialog h5.modal-title { font-size: 27px; color: #155090; text-align: center; text-transform: uppercase; width: 100%; }

.customModal .modal-footer .btn { min-width: 140px; }

.modal-header .close { padding: 15px 15px 10px; }

/** Mat Modal Css **/
.mat-dialog-container { padding: 0px!important; }
.mat-dialog-content { margin: 0; padding: 0; }
.mat-dialog-container .matModal { padding: 1rem; }
.matModal button.mat-button { margin-left: auto; padding: 0; min-width: 32px; height: 22px; background: transparent; }
.matModal .mat-button .mat-button-focus-overlay, .matModal .mat-button:hover .mat-button-focus-overlay, .matModal .mat-button-ripple.mat-ripple{opacity: 0!important;}
.matModal .modal-header { margin: 0 -1rem; padding-top: 0; margin-bottom: 1rem; }
.matModal .modal-header .modal-title { font-size: 1.2rem; color: #084e94; font-weight: 600; }
.matModal .modal-header .close { height: auto; width: 50px; min-width: auto; line-height: 1; padding: 16px 0px; }


/** Applied Modal Css **/
.customModal .modal .modal-dialog.modal-applied { max-width: 500px; text-align: center; }
.modal-applied .modal-header { border-bottom: 0; padding: 0; }
.modal-applied span.icon i { font-size: 5rem; }
.modal-applied span.icon { margin-bottom: 1rem; display: block; }
.modal-applied h2 { margin-bottom: 1rem; font-size: 23px; text-transform: uppercase; }
.modal-applied .modal-body { line-height: 2rem; }
.modal-applied .close { margin: 0 0 0 auto; position: absolute; right: 0; z-index: 1; }



/** information Modal Css **/
.informationModal { position: fixed; top: 0; left: 0; right: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,.55); z-index: 1; overflow-y: auto; }
.jobInformationModal .modal-dialog { max-width: 767px; width: 100%; font-size: 14px; }
.jobInformationModal .modal-title { text-align: center; padding: 2rem 2rem 0.5rem; color: #767575; }
.jobInformationModal  .modal-title h5 { font-weight: 600; font-size: 1.8rem; margin-bottom: 0; }
.jobInformationModal .modal-body{padding: 0 2rem 0rem;}
.jobInformationModal .modal-body .mat-dialog-content {max-height: none;}
.jobInformationModal .Jobdescription { font-weight: 400; line-height: 1.8rem; margin-bottom: 1rem; }
.jobInformationModal .Jobdescription h3 { font-weight: 600; margin-bottom: 8px; }
.jobInformationModal .Jobdescription p{ font-size: 13px; line-height: 1.3rem; }
.jobInformationModal .jobduration { border-right: 1px solid #d7d7d7; height: 100%; }
.jobInformationModal .jobduration li { line-height: 1.35rem; margin-bottom: 15px; display: flex; align-items: center; flex-wrap: wrap; }
.jobInformationModal .jobduration li label { width: 121px; word-break: break-word; font-size: 13px; font-weight: 600; line-height: 1.2rem; }
.jobInformationModal .bottom-button .btn { min-width: 162px; }
.jobInformationModal .br-0{border-right: 0px!important;}

/* information clientinfo css */
.clientinfo { display: flex; display: -webkit-flex; max-width: 958px; margin: 2rem auto 0.5rem; width: 100%; position:relative; }
.clientinfo:after { content: ""; background: #fff; border-top: 1px dotted #cccccc; display: block; width: 100%; max-width: 730px; height: 2px; left: 0; right: 0; position: absolute; margin: auto; top: 40px; }
.clientinfo li { margin-bottom: 1rem; flex-basis: 25%; padding: 0 0.4rem; position: relative; z-index: 1; }
.clientinfo .icon { width: 80px; height: 80px; border-radius: 100%; margin: auto; display: flex; align-items: center; justify-content: center; margin-bottom: 1rem; z-index: 1; position: relative;}
.clientinfo .icon img{width: auto;}
.clientinfo .text { text-align: center; color: #767575; font-weight: 400; line-height: 1.5rem; }
.clientinfo .text h5 { color: #303030; font-weight: 600; margin-bottom: 0.6rem; }
.clientinfo li:first-child:after, .clientinfo li:last-child:after { content: ""; background: #fff; height: 20px; position: absolute; left: 0px; width: 50%; top: 27px; }
.clientinfo li:last-child:after { left: auto; right: 0; }


.mat-dialog-container { color: #000000; }


/* Information Modal */
.informationModal .modal-dialog { max-width: 900px; }
.informationModal .modal-dialog .modal-content { border-radius: 10px; overflow: hidden; }
.informationModal .modal-body { display: flex; padding: 0; }

.informationModal .rightSection { order: 1; background: #f7f9fb; padding: 2rem; flex-basis: 320px; min-width: 320px; margin-left: auto; }
.informationModal .leftSection { padding: 2rem; flex-basis: 100%; }

.informationModal .listDetail li { margin-bottom: 1.5rem; color: #000; font-size: 15px; line-height: 1.3rem; }
.informationModal .listDetail li label { color: #3d505a; display: block; margin-bottom: 1px; }
.informationModal .listDetail li .icon { min-width: 40px; height: 40px; background: #fff; border-radius: 100%; margin-right: 14px; text-align: center; display: flex; align-items: center; justify-content: center; }
.informationModal .listIcon li { display: flex; align-items: center; }
.informationModal h2 { font-weight: 600; font-size: 1.4rem; margin-bottom: 1.7rem; }

.informationModal .description { font-size: 13px; margin-bottom: 2rem; }
.informationModal .description p { margin-bottom: 1rem; line-height: 1.2rem; font-size: 12px; }
.informationModal .description h3 { margin-bottom: 1rem; font-weight: 600; font-size: 1.2rem; }

.informationModal .text-group h3 { font-weight: 400; margin-bottom: 0.8rem; font-size: 1.2rem; }
.informationModal .list-flex { display: flex; display: -wbekit-flex; flex-wrap: wrap; }
.informationModal .list-flex li { margin-right: 1.4rem; }
/*
.informationModal  button.close { color: #fff; font-weight: 400; opacity: 1; text-shadow: none; position: relative; right: -26px; cursor: pointer; pointer-events: auto;}
.informationModal button.close i { font-size: 2rem; }
*/

.jobType table { border: 0; }
.jobType table td,.jobType table th {
border: 0;
text-align: center;
background: #f7f9fb;
border: 0;
padding: 12px 10px;
min-height: 50px;
border-top: 10px solid #fff;
border-bottom: 10px solid #fff;
font-size: 15px;
line-height: 1.2rem;
}

.jobType table td:first-child, .jobType table th:first-child {
text-align: left;
}

.jobType table th {
background: #fff;
color: #93aab7;
font-weight: 500;
}

.jobType table th.active {
color: #0d4e94;
}

.jobType table th:first-child {
color: #111;
}

.jobType table td.active {
background: #0d4e94;
color: #fff;
border-top: 10px solid #0d4e94;
border-bottom: 10px solid #0d4e94;
position: relative;
}

.jobType table tbody tr:first-child td.active:before, .jobType table tbody tr:last-child td.active:before {
  content: "";
  background: #0d4e94;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 10px;
  left: 0;
  top: -10px;
  border-radius: 5px 5px 0 0;
}
.jobType table tbody tr:last-child td.active:before {
  top: auto;
  bottom: -13px;
  border-radius: 0px 0px 5px 5px;
}

@media (max-width: 900px) {
  .informationModal button.close { right: 0; }
}
@media (max-width: 640px) {
  .informationModal .rightSection, .informationModal .leftSection { flex-basis: 100%; min-width: 100%; }
  .informationModal .modal-body { flex-wrap: wrap; }
  .informationModal .rightSection ul.listDetail { display: flex; display: -wbekit-flex; flex-wrap: wrap; }
  .informationModal .listDetail li { margin-right: 1.4rem; }
}

/*-------------------------------------------------------*/
/*  Filter Siderbar  Css
/*-------------------------------------------------------*/
.candidateList .candidateListForm { display: flex; display: -webkit-flex; }
.candidateListRight{ min-width: calc(100% - 260px); padding: 0 0 0 15px; flex-grow: 1; }

.candidateList .filterIcon { display: none; width: 20px; cursor: pointer; margin-right: 5px; }
.candidateList.openFilter .filterIcon { display: inline-block; }
.candidateList.closeFilter .candidateListRight { padding: 0px; }

.filterSidebar { background: #ffffff; width: 229px; padding: 15px; position: relative; margin: -15px 0 0 -20px; z-index: 1; min-width: 229px; }

.filterSidebar.close { display:none; opacity: 1; float: none; width: 42px; min-width: 42px; background: transparent; padding: 15px 0px 15px 15px; }

.filterSidebar .mat-expansion-panel { background: #f6f6f6; border-radius: 0; box-shadow: none;margin-bottom: 15px; }
.filterSidebar .mat-expansion-panel .mat-expansion-panel-header { background: #f6f6f6; padding: 10px 15px; height: auto!important; position: relative; color: #0d4e94; font-weight: 600; }
.filterSidebar .mat-expansion-panel.mat-expanded .mat-expansion-panel-header:after {content: "";background: #dddddd;height: 1px;width: calc(100% - 30px);display: block;position: absolute;left: 15px;bottom: 0px;}
.filterSidebar .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title { color: #0d4e94; font-size: 16px; }
.filterSidebar .mat-expansion-panel-content .mat-expansion-panel-body { padding: 15px; }
.filterSidebar .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover { background: #f6f6f6; }
.filterSidebar .mat-expansion-panel-content .mat-expansion-panel-body ul li { color: #0d4e94; font-size: 16px; margin-bottom: 8px; }

.filterSidebar .custom-radio label { cursor: pointer; position: relative; padding-left: 28px; }

.filterSidebar .form-control { background: transparent; color: #094381; border: 0; font-size: 15px; border-bottom: 1px solid #d9d9d9; padding: 5px 0; }
.filterSidebar h3 { position: relative; color: #0d4e94; text-transform: uppercase; font-weight: 600; margin-bottom: 20px; font-size: 18px }

.filterSidebar .form-buttom .btn{ padding: .45rem 1rem;}
.filterSidebar .humburger { display: block; cursor: pointer; top: 11px; right: 0; left: auto; width: 27px; position: absolute; z-index: 1; height: 22px; padding-top: 10px; }
.filterSidebar .humburger span { display: block; position: absolute; height: 3px; max-width: 26px; margin: auto; right: 0; width: 100%; background: #111111; opacity: 1; left: 0; -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); -webkit-transition: .25s ease-in-out; -moz-transition: .25s ease-in-out; -o-transition: .25s ease-in-out; transition: .25s ease-in-out; }
.filterSidebar.open .humburger { right: 16px; }
.filterSidebar .humburger span:nth-child(2) { -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -o-transform: rotate(45deg); transform: rotate(45deg); }
.filterSidebar .humburger span:nth-child(3) { -webkit-transform: rotate(-45deg); -moz-transform: rotate(-45deg); -o-transform: rotate(-45deg); transform: rotate(-45deg); }
.filterSidebar .humburger span:nth-child(4),   .filterSidebar .humburger span:nth-child(1)  { width: 0%; }

.filterSidebar.close .humburger span:nth-child(2),.filterSidebar.close .humburger span:nth-child(3) { top: 14px; -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); }
.filterSidebar.close .humburger span:nth-child(4) { top: 22px; left: 0; width: 100%; }
.filterSidebar.close .humburger  span:nth-child(1) { top: 6px; left: 0; width: 100%; }


.filterSidebar.close .filteraccordian, .filterSidebar.close h3 { display: none; }

@media (max-width: 767px) {
    .filterSidebar.open {position: absolute;
      z-index: 9;
      width: 199px; }
    .filterSidebar .input_div  ul li{ font-size: 13px;}

  .filterSidebar .mat-expansion-panel-content .mat-expansion-panel-body ul li{ font-size: 13px;}
}


/**** Candidates Search Filter Css ***/
.searchFilter .search { position: relative; }
.searchFilter .search input[type="search"],.searchFilter .search input[type="text"] { border: 1px solid #e0e0e0; border-radius: 5px; display: block; width: 100%; height: 52px; padding: 15px; font-size: 15px; padding-right: 34px; }

.searchFilter .search .btn-search { background: transparent; border: 0; position: absolute; right: 0; top: 0; color: #0d4e94; width: 40px; height: 52px; }
.searchFilter .search .btn-search i { vertical-align: middle; }
.searchFilter .selectgroup { background: #fff; border: 1px solid #e0e0e0; border-radius: 5px; height: 52px; padding: 15px 0px 15px 15px; font-size: 15px; display: flex; display: -webkit-flex; align-items: center; }
.searchFilter .selectgroup > label { font-weight: 600; font-size: 14px; white-space: nowrap; margin-right: 10px; }
.searchFilter .selectgroup .mat-form-field-underline { display: none; }
.searchFilter .selectgroup .mat-form-field-wrapper { padding: 0; }
.searchFilter .selectgroup .mat-form-field { display: inline-table; line-height: 1; position: relative; height: 52px; margin-top: 0; }
.searchFilter .selectgroup .mat-form-field-wrapper .mat-form-field-infix { margin: 0; align-items: flex-start; width: 146px; }
.searchFilter .selectgroup .mat-form-field-flex { align-items: baseline; }
.searchFilter .selectgroup .mat-select-arrow-wrapper { background: #e8e8e8; top: -17px; width: 36px; position: absolute; right: 0px; height: 51px; border-radius: 0px 5px 5px 0px; }
.searchFilter .selectgroup .mat-select-trigger { width: 100%; }
.searchFilter .selectgroup  .mat-select-arrow-wrapper .mat-select-arrow {margin: 23px auto 0;}
.searchFilter .selectgroup .mat-form-field-label-wrapper { display: none; }
.searchFilter .selectgroup .mat-select-value-text { color: #0d4e94; font-weight: 600; font-size: 14px; }

.searchFilter .selectgroup .form-control{  border: 0px;}
.searchFilter .selectgroup .selectfield { background: url('/assets/images/selectbg.png'); background-repeat: no-repeat; background-position: center right; border: 0px; height: 53px; appearance: none; -webkit-appearance: none; -moz-appearance: none; margin-left:auto; padding-right: 52px;}

.searchFilter .button .btn{ height: 52px;}



.field-date .mat-form-field-wrapper { padding: 0; }
.field-date .mat-form-field-wrapper .mat-form-field-underline { display: none; }
.field-date .mat-form-field-wrapper .mat-form-field-flex {background: #fff;border: 1px solid #ced4da; width: 100%;padding: .475rem .75rem; font-size: 14px; height: calc(2.25rem + 2px);}
.field-date .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix { width: auto; margin: 0; border: 0; padding: 0; }
.field-date .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-label-wrapper { min-height: 16px; top: 0; bottom: 0; padding: 0; margin: auto; }
.field-date .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-label-wrapper .mat-form-field-label { transform: none; -webkit-transform: none; top: 0; }
.field-date .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper { position: relative; width: 100%; margin-top: 0; }
.field-date .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper .mat-error { width: auto; margin-top: 0.25rem; font-size: 13px; margin-right: 10px; color: #dc3545; }
.field-date .mat-form-field-invalid .mat-input-element {caret-color: #dc3545;}
.field-date .mat-form-field.mat-form-field-invalid .mat-form-field-label{color: #dc3545;}
.field-date .mat-form-field-invalid  .mat-form-field-wrapper .mat-form-field-flex {
  border-color: red;}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper > div {
display: flex;
display: -webkit-flex;
flex-wrap: wrap;
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper .mat-error:last-child {
margin-right: 0px;
}

.searchFilter .field-date .mat-form-field-wrapper .mat-form-field-flex{ border-color:#e0e0e0; border-radius: 5px; padding: 15px;font-size: 15px;height: 52px;}


.img_up_wrapper .ngx-img-wrapper .ngx-img-message { display: none !important; }

.disabled { pointer-events: none; opacity: 0.5; cursor: not-allowed; }

.mat-select-disabled {  background: #e9ecef; }
.mat-select-disabled .mat-select-value {
  color: #111;
}

.skill_type .ng-select .ng-arrow-wrapper { display: none; }
.skill_type .ng-select-container {
  background-color: #fff !important;
  border-radius: 10px !important;
  border: none !important;
  min-height: 54px !important;
  align-items: center;
  border-right: 1px solid #ccc !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  width: 111% !important;
}

.country .ng-select-container {
  /* background-color: #fff !important; */
  border-radius: none !important;
  /* border: none !important; */
  min-height: 54px !important;
  align-items: center;
  width: 111% !important;
}

.city .ng-select .ng-arrow-wrapper {
  display: none;
}

.city .ng-select-container {
  background-color: #fff !important;
  background: transparent;
  border-radius: none !important;
  min-height: 54px !important;
  align-items: center;
  width: 111% !important;
  height: 54px;
  overflow-y: scroll !important;
  /* border-left: 1px solid #ccc !important; */
  /* border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; */
}

.row.searchbar .col-sm-3 {
  flex: 1 33%;
  max-width: 34%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.DashboardStaffing {
  background: #fff;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 17px;
  padding-bottom: 5px;
  padding-left: 3px;
  COLOR: #155090;
  font-size: 15px;
  font-weight: 600;
}
.ngx-img-wrapper input {
  position: absolute;
  top: auto !important;
  right: 0px;
  bottom: 13px !important;
  left: auto !important;
  opacity: 0 !important;
  cursor: pointer;
  z-index: 3;
  width: 40px !important;
  height: 40px !important;
  background-color: #3174bc !important;
  border-radius: 50% !important;
}

.ngx-img-wrapper:hover {
  background-image: unset !important;
}

.img_up_wrapper .ngx-img-wrapper {
  display: block;
  /* z-index: 1;
  overflow: hidden;
  text-align: center;
  width: 160px !important;
  height: 160px !important;
  margin: -80px auto 30px;
  position: relative;
  background-color: #e3e3e3 !important;
  border-radius: 50% !important;
  border: none !important; */
}

.img_up_wrapper .ngx-img-wrapper {
  overflow: unset !important;
  width: 160px !important;
  height: 160px !important;
  border: 1px solid #e5e5e5 !important;
  position: relative !important;
  margin: 0px auto 30px !important;
  background-color: #e3e3e3 !important;
  padding: 0 !important;
  border-radius: 50% !important;
}

.ngx-img-wrapper .ngx-img-preview {
  position: relative !important;
  display: block;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  width: 160px !important;
  height: 160px !important;
  margin: 0px auto;
  border-radius: 50% !important;
  border: none !important;
  padding: 0 !important;
}

.ngx-img-render {
  display: block;
  width: 100%;
  height: 100%;
}

.ngx-img-wrapper .ngx-img-preview .ngx-img-render img {
  top: auto !important;
  transform: unset !important;
  position: relative !important;
  ;
  width: 100%;
  height: 100%;
  background-color: #fff;
  object-fit: cover;
  object-position: center;
  /* background-image:url('/assets/images/user.jpg'); */
}

.popupresum_detal_wrap {
  padding: 30px;
}

.btn_cv_push[_ngcontent-c9] {
  margin-bottom: 0;
  margin-top: 15px;
}

.comm_rsm_sec {
  margin-bottom: 10px;
}

.owl-dt-inline-container,
.owl-dt-popup-container {
  box-shadow: none;
}

.ngx-img-wrapper .has-preview {
  border: 0;
  width: 160px;
  height: 160px;
  margin: 0 auto;
  position: relative;
  padding: 0;
  background-color: #ccc;
}

button:focus {
  outline: 0 !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border-color: rgb(0, 150, 111);
}

.ngx-img-wrapper:hover .ngx-img-clear,
.ngx-img-wrapper:hover .ngx-img-preview .ngx-img-infos {
  display: none;
}

.ngx-img-clear {
  display: none !important;
}


/* file upload css */



.mat-dialog-container{
    padding: 0px !important;
}


.owl-dt-calendar-main{
    padding: 0 1.5em 1.5em !important;
    padding-bottom:  0 1.5em 1.5em !important;
}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
    visibility: inherit !important;

}
.owl-dt-container-inner .owl-dt-calendar-main {
  padding-bottom: 0px!important;
}

.owl-dt-inline-container .owl-dt-calendar, .owl-dt-popup-container .owl-dt-calendar, .owl-dt-timer  {
  height: auto;
}

.owl-dt-timer{
/* height: 0em !important;  */
padding: 0em !important;
}
.input-file-container .input-icon,
.input-file-container .mat-orphan-label {
  display: none;
}

.file-container button.secondary-button.mat-button {
  display: none;
}

.input-file-container .file-name {
  width: 100%;
}

.input-file-container .file-button,
.input-file-container .file-container {
  display: block !important;
  height: auto !important;
}

.input-file-container .file-button {
  flex: unset !important;
}

button.file-button.mat-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 25px !important;
  height: 25px !important;
  padding: 0;
  opacity: 0;
  z-index: 9;
}

.input-file-container .files-container {
  padding: 0 !important;
  display: block !important;
  min-height: 38px;
}

.input-file-container .replace-button {
  flex: unset !important;
  width: 90%!important;
  background-color: transparent!important;
}

.input_wrap input-file.form-control {
  padding: 0 !important;
}


/* .ng-invalid {   border-color: #ba3f3f;} */

.preloader img {
    width: 96px;
    height: auto;
    /* box-shadow: 6px 10px 7px -11px #333; */
}

.preloader {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9607843137254902);
}


/*
 *  STYLE 8
 */

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

::-webkit-scrollbar {
    width: 0.5vw;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color:#999999;
}

/*-------------------------------------------------------*/
/* Animation Css
/*-------------------------------------------------------*/
.animated--grow-in{-webkit-animation-name:growIn;animation-name:growIn;-webkit-animation-duration:.2s;animation-duration:.2s;-webkit-animation-timing-function:transform cubic-bezier(.18,1.25,.4,1),opacity cubic-bezier(0,1,.4,1);animation-timing-function:transform cubic-bezier(.18,1.25,.4,1),opacity cubic-bezier(0,1,.4,1)}
@-webkit-keyframes growIn{0%{-webkit-transform:scale(.9);transform:scale(.9);opacity:0}100%{-webkit-transform:scale(1);transform:scale(1);opacity:1}}@keyframes growIn{0%{-webkit-transform:scale(.9);transform:scale(.9);opacity:0}100%{-webkit-transform:scale(1);transform:scale(1);opacity:1}}

footer.sticky-footer { background: #fff; padding: 14px 15px; color: #505050; font-size: 15px; }


/*-------------------------------------------------------*/
/* customForm Css
/*-------------------------------------------------------*/
.form-label{ display: block; margin-bottom: 5px; font-size: 14px; font-weight: 500; text-transform: uppercase;}
.form-control{ border-radius: 0; outline: 0; color: #111111; font-size: 14px;outline: none; height: calc(2.25rem + 2px); }
.errorMsg { color: red; font-size: 14px; font-weight: 600;}

.form-control .mat-input-element { color: #111111; }
.field-date .mat-form-field {
  width: 100%;
}

.customForm tag { line-height: 18px!important; font-size: 13px!important; height: 19px!important; border-radius: 3px!important; letter-spacing: 0!important; padding: 1px 2px 1px 5px!important; }
.customForm tag delete-icon { width: 16px; transform: none!important; }
.customForm tag delete-icon svg { height: 11px!important; vertical-align: middle!important; }
.customForm tag-input-form input { height: 26px!important; }
.custom-checkbox .custom-control-label::before { border-radius: 0; }
.custom-control-input:checked ~ .custom-control-label::before { border-color: #0d4e94; background-color: #0d4e94; }
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after { content: ""; background: transparent; position: absolute; left: -18px; top: 4px; width: 6px; height: 10px; border: solid #084e94; border-width: 0 2px 2px 0; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); }
.custom-control-input:checked ~ .custom-control-label::before {
  background: #fff;
} */

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    /*   border: 4px solid #FFF;
    box-shadow: 0px 0px 0px 2px #0d4e94;*/
  /* background: #0d4e94;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    left: -24px;
    top: 3px; */
    background: #0d4e94;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    left: -1.25rem;
    top: 0.35rem;
}
.checkboxGroup{ display: block; width: 100%;
padding: .375rem .75rem;
color: #495057;
background: #fff;
border: 1px solid #ced4da;
}
.customForm  .custom-checkbox { margin-right: 1rem; }
.customForm .custom-checkbox [type="radio"].with-gap:checked+label:before,.customForm .custom-checkbox [type="radio"]:checked+label:after, .customForm .custom-checkbox [type="radio"]:not(:checked)+label:before, .customForm  .custom-checkbox [type="radio"]:not(:checked)+label:after { border-color: #0d4e94; }
.customForm  .custom-checkbox [type="radio"]:checked+label:after, .customForm  .custom-checkbox [type="radio"].with-gap:checked+label:after{ background-color: #0d4e94;}

.customForm .mat-form-field { width: 100%; }

.customForm h2.title { padding: 0; font-size: 1.3rem; font-weight: 600; margin-bottom: 20px; }
.customForm textarea.form-control { resize: none; height: 82px; }

.customForm .form-text { font-size: 12px; }
.req { color: red; }

.control-radio, .control-checkbox { margin: 0.5rem 0; }
.control-radio label.custom-control-label, .control-checkbox label.custom-control-label{ line-height: 1.15rem; font-size: 15px; cursor: pointer; position: relative; }
.control-radio input[type="radio"], .control-checkbox input[type="checkbox"]  { position: absolute; opacity: 0; }
.control-radio input[type="radio"] + .custom-control-label:before, .control-checkbox input[type="checkbox"] + .custom-control-label:before{
content: '';
background: #fff;
border-radius: 100%;
border: 1px solid #094381;
display: inline-block;
width: 16px;
height: 16px;
position: relative;
top: 0;
left: 0px;
margin-right: 6px;
vertical-align: top;
cursor: pointer;
text-align: center;
transition: all 250ms ease;
}
.control-checkbox input[type="checkbox"] + .custom-control-label:before{border-radius: 2px;}

.control-radio input[type="radio"]:checked + .custom-control-label:before{ background-color: #094381; box-shadow: inset 0 0 0 2px #fff; }
.control-radio input[type="radio"]:focus + .custom-control-label:before { outline: none; border-color: #094381; }
.control-radio input[type="radio"]:disabled + .custom-control-label:before { box-shadow: inset 0 0 0 2px #f4f4f4; border-color: #b4b4b4; background: #b4b4b4; }
.control-radio input[type="radio"] + .custom-control-label:empty:before { margin-right: 0; }
.control-checkbox input[type="checkbox"]:checked + .custom-control-label:after { content: ''; display: block; position: absolute; top: 2px; left: 5px; width: 6px; height: 10px; border: solid #094381; border-width: 0 2px 2px 0; transform: rotate(45deg); }


.form-upload h4 { font-size: 16px; color: #0d4e94; margin-bottom: 4px; text-transform: uppercase; font-weight: 600; }
.form-upload .custom-file label.btnFile { color: #0d4e94; border: 2px solid currentColor; font-size: 1rem; text-overflow: ellipsis; text-transform: uppercase; font-weight: 500; white-space: nowrap; cursor: pointer; display: inline-block; overflow: hidden; padding: 0.625rem 1rem; }
.form-upload .custom-file { width: 100%; max-width: 216px; }
.form-upload .custom-file label.btnFile i { margin-right: 4px; }
.form-upload .custom-file .helpTxt { font-size: 14px; color: #6b6b6b; margin: 3px 0 8px; }
.badge-primary { background: #0d4e94; }
.form-upload .upload-file .loaderFile { width: 36px; }

.field-upload .input-file { position: relative; }
.field-upload .input-file .btn-file { padding: .42rem 0px; border-radius: 0; position: absolute; left: 1px; top: 1px; height: 36px; font-weight: 500; font-size: 13px; text-transform: uppercase; width: 103px; }
.resizenone, .resizeno{ resize: none;}

.form-date .mat-form-field-wrapper { top: -8px; }
.form-control .mat-select-value { padding: 3px 0; }

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background{background: #0d4e94;}

.customSelect { line-height: 1; margin: 0; }
.customSelect .mat-form-field-wrapper { padding-bottom: 0; }
.customSelect .mat-form-field-wrapper .mat-form-field-infix { padding: 0; margin: 0; border: 0; }
.customSelect .mat-form-field-wrapper .mat-form-field-underline { bottom: 0; }
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element { background: #0d4e94; }

/*** Extra Css ***/
.recentBox .card-default .card-header  .mat-paginator-container { min-height: 40px; padding: 0; }
.recentBox .card-default .card-header  .mat-paginator-container .mat-paginator-page-size-select { line-height: 1; padding: 0; margin: 0; }
.recentBox .card-default .card-header .mat-paginator .mat-paginator-outer-container{ margin-top: -5px; }
.recentBox .card-default .card-header .mat-paginator-range-label { margin: 0 5px 0 0; }
.recentBox .card-default .card-header .mat-icon-button { height: 24px; width: 24px; }
.recentBox .card-default .card-header .mat-button-ripple.mat-ripple { display: none; }
.recentBox .card-default .card-header .mat-icon-button .mat-button-focus-overlay { display: none; }
.recentBox .card-default .card-header .mat-paginator-icon { vertical-align: top;  position: relative;  top: -2px; left: -2px; }
.recentBox .card-default .card-header .mat-form-field-appearance-legacy .mat-form-field-wrapper { padding: 0;  }
.recentBox .card-default .card-header .mat-paginator-page-size-label { margin-right: 8px; }
.recentBox .card-default .card-header .mat-form-field-appearance-legacy .mat-form-field-infix { padding: 0; margin: 0; border: 0; }
.recentBox .card-default .card-header .mat-form-field-label-wrapper { display: none;}
.recentBox .card-default .card-header .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {  display: none; }
.recentBox .card-default .card-header .mat-form-field-appearance-legacy .mat-form-field-underline {   bottom: 0; }
.recentBox .card-default .card-header .mat-select-value {   font-size: 15px; }


.profileStepForm .mat-stepper-horizontal { background: transparent; }

/* Interview Page */
.rightCalendar .calendar .cal-month-view .cal-header { border: 1px solid #e6e6e6; padding: 1px 5px; border-bottom: 0; }
.rightCalendar .calendar .cal-month-view .cal-header .cal-cell { font-weight: 500; text-transform: uppercase; }
.rightCalendar .calendar .cal-month-view .cal-day-cell { min-height: 60px; }
.rightCalendar .calendar .cal-month-view .cal-day-cell .cal-day-badge { margin: 2px; position: absolute; top: 0px; padding: 3px 3px; max-width: 18px; min-width: 18px; text-align: center; }
.rightCalendar .calendar .cal-month-view .cal-day-cell .cal-cell-top { position: relative; min-height: 38px; }
.rightCalendar .calendar .cal-month-view .cal-day-cell .cal-cell-top .cal-day-number { margin-bottom: 0px; }

/*-------------------------------------------------------*/
/* Modal Login Css
/*-------------------------------------------------------*/
.modal-custom{ background: rgba(0,0,0,0.5); position: fixed; top: 0; right: 0; bottom: 0; left: 0; overflow-x: hidden; overflow-y: auto; z-index: 99;}
.modal-custom .overlay{ width: 100%; height: 100%; top: 0; left: 0; position: fixed; z-index: 8; }
.modal-custom .modalDialog{ align-items: center; display: flex; display: -webkit-flex; max-width: 892px; width: 100%; margin: 30px auto; padding: 0 15px; position: relative; justify-content: center; min-height: 100%; z-index: 9; }
.modal-custom .modalContent{ background: #fff; width: 100%;}
.modal-custom .modalClose{ cursor: pointer;}
.modal-custom .modalClose i { vertical-align: middle; }


.modal-login .modal-content .modalBody{ display: flex; display: -webkit-flex;}
.modal-login .leftContent{width: 40%;
/*background: #2e5485 url(/assets/images/signup_bg_img.png) no-repeat  center -93px ;*/
background: #36312D no-repeat  center -93px ;
background-size: cover;
position: relative;
padding: 30px;}
.modal-login .leftContent .logo{    margin-bottom: 30px;
text-align: center;}
.modal-login .leftContent .logo img {
width: 200px;
}
.modal-login .leftContent .text p{    font-size: 14px;
color: #fff;
margin-bottom: 25px;
line-height: 1.528;}

.modal-header h2{
color: #084e94;
font-weight: 600;
font-size: 1.2rem;
}
.formPanel { flex-basis: 60%; padding: 20px; }
.formPanel .title { margin-bottom: 26px;}
.formPanel .title h4 { color: #084e94; font-weight: 600; font-size: 2rem; margin-bottom: 8px; text-transform: uppercase; }
.formPanel .title p { font-size: 14px; color: #5d5e5e; }

.formPanel .contentTab { border: 1px solid #ddd; }

.formPanel .contentTab ul.loginTab { display: flex; display: -webkit-flex; flex-wrap: wrap; }
.formPanel .contentTab ul.loginTab li { background: #f4f6f8; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd; flex-basis: 25%; text-align: center; padding: 10px 5px; font-size: 13px; position: relative; cursor: pointer; }
.formPanel .contentTab ul.loginTab li:last-child { border-right: 0; }

.formPanel .contentTab ul.loginTab li.active { background: #ffffff; border-bottom: 0; }
.formPanel .contentTab ul.loginTab li .icon { display: block; margin-bottom: 6px; width: 32px; margin: auto; }
.formPanel .contentTab ul.loginTab li .icon img.active, .formPanel .contentTab ul.loginTab li.active .icon img { display: none; }
.formPanel .contentTab ul.loginTab li .label { display: block; margin-top: 6px; }
.formPanel .contentTab ul.loginTab li.active .icon img.active { display: block; }
.formPanel .contentTab ul.loginTab li a .label{ color: #000000;}
.formPanel .contentTab ul.loginTab li.active .label{ color: #084e94; }
.formPanel .contentTab ul.loginTab li.active:after {content: "";background: #084e94;height: 2px;width: 100%;position: absolute;left: 0;top: -1px;}

.form-content{ padding: 15px;}
.form-content .formLabel { font-size: 14px; margin-bottom: 5px; }
.form-content .forgotLink { font-size: 13px; }
.form-content .font14{ font-size: 14px;}
.form-content .btn-link { color: #fc434e; font-size: 14px; }
.form-content .width30{ width: 30%;}
.form-content .formButton button.btn{min-height: 42px; padding: 10px 30px; border-radius: 30px; font-size: 16px;}

.modal-register .formPanel .contentTab ul.loginTab li { flex-basis: 33.33%; }
.modal-login .form-content .row { margin-right: -7.5px; margin-left: -7.5px; }
.modal-login .form-content .col-md-6 { padding-left: 7.5px; padding-right: 7.5px; }



/**** Profile Page Css ***/
.profileStepForm .mat-horizontal-stepper-header-container {
background: #ffffff;
border: 1px solid #ebebeb;
border-top: 0px;
box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01);
-webkit-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01);
padding: 15px;    flex-wrap: wrap;
}
  .profileStepForm .mat-horizontal-content-container, .profileStepForm  .mat-horizontal-stepper-content form { padding: 0px;}
  .profileStepForm .mat-horizontal-stepper-header, .profileStepForm .mat-horizontal-stepper-header:hover{background: transparent;}
  .profileStepForm .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header .mat-step-icon {background: rgb(54,38,173); background: linear-gradient(to right, rgba(54,38,173,1) 0%,rgba(174,96,208,1) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3626ad', endColorstr='#ae60d0',GradientType=1 ); width: 50px; height: 50px; position: relative; z-index: 1;}
  .profileStepForm .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header[aria-controls='cdk-step-content-0-1'] .mat-step-icon,.profileStepForm .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header:nth-child(3) .mat-step-icon { background: rgb(255,126,154); background: linear-gradient(to right, rgba(255,126,154,1) 0%,rgba(254,184,122,1) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7e9a', endColorstr='#feb87a',GradientType=1 ); }
  .profileStepForm .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header[aria-controls='cdk-step-content-0-2'] .mat-step-icon,.profileStepForm .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header:nth-child(5) .mat-step-icon { background: rgb(106,122,244); background: linear-gradient(to right, rgba(106,122,244,1) 0%,rgba(90,214,250,1) 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6a7af4', endColorstr='#5ad6fa',GradientType=1 );  }
  .profileStepForm .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header[aria-controls='cdk-step-content-0-3']  .mat-step-icon,.profileStepForm .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header:nth-child(7) .mat-step-icon { background: #00A292; background: linear-gradient(to right, #00A292 0%,#03C791 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffbc30', endColorstr='#ffdd26',GradientType=1 );  }
  .profileStepForm .mat-step-label.mat-step-label-active.mat-step-label-selected:after, .profileStepForm .mat-step-header .mat-step-header-ripple, .profileStepForm .mat-horizontal-stepper-header  .mat-step-icon-content span { display: none; }
  .profileStepForm .mat-horizontal-stepper-header .mat-step-label{ font-size: 16px; font-weight: 600; }
  .profileStepForm .mat-horizontal-stepper-header .mat-step-icon img { width: 15px; height: 15px;   }
  .profileStepForm .mat-horizontal-stepper-header .mat-step-label .icon { width: 24px; height: 24px; position: absolute; top: 25px; left: 0; right: 0; margin: auto; z-index: 2; }
  .profileStepForm .mat-stepper-label-position-bottom .mat-horizontal-stepper-header { padding: 13px; }
  .profileStepForm .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header .mat-step-icon.mat-step-icon-selected { border: 6px solid #E5E5E5; width: 62px; height: 62px; position: relative; top: -6px; }

  .profileStepForm .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header .mat-step-label.mat-step-label-selected {margin-top: -12px;}
  .profileStepForm .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label-active:before { background: #00A651; content: ""; width: 8px; height: 8px; display: block; border-radius: 100%; position: absolute; bottom: 34px; left: 0; right: 0; margin: auto; }
  .profileStepForm .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header .mat-step-label.mat-step-label-selected:before{display:none;}
  .profileStepForm .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header .mat-step-icon .mat-step-icon-content {
    display: none;
}

.profileAddSection { position: relative;  }

.profileAddSection .profileinfoTxt { background: #ffffff; border: 1px solid #ebebeb; border-bottom: 0; box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.01); -webkit-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01); padding: 15px; }
.profileAddSection .profileinfoTxt p { margin-bottom: 15px; }
.profileAddSection .profileinfoTxt h2 { font-weight: 600; color: #111; margin-bottom: 15px; font-size: 20px; }
.profileAddSection .profileDetailSection{ background: #ffffff; border: 1px solid #ebebeb; box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01); -webkit-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01); padding: 15px; margin: 15px 0 30px; }
.profileAddSection .profileDetailSection h2.title { padding: 0; font-size: 1.3rem; font-weight: 600; margin-bottom: 30px; }
.profileDetailwithform .profileDetailForm {
  margin-left: 260px;
}
.profileDetailSection h2.title:after {content: "";background: #0d4e94;width: 30px;height: 2px;display: block;margin-top: 6px;margin-bottom: 20px;}
.profileDetailSection h2.subtitle { color: #0d4e94;  margin-bottom: 15px; text-transform: uppercase; }
.profileDetailSection .form_title { font-size: 13px; text-transform: uppercase; }
.profileDetailSection .form-group{ margin-bottom: 1rem;}


.profileDetailSection .mat-select-placeholder{ color: #111;
  opacity: 0.7;}
.profileDetailSection .mat-form-field-appearance-legacy .mat-form-field-wrapper { width: 100%; padding-bottom: 1rem; }

.profileDetailSection .mat-form-field-infix { width: auto; font-size: 14px; color: #111; }
.profileDetailSection .mat-form-field-label-wrapper { top: -17px; }

.profileDetailSection .mat-form-field-appearance-legacy .mat-form-field-label {     color: #111;
  opacity: 0.7;}

.profileDetailSection .mat-form-field-appearance-legacy .mat-form-field-underline { background: #dde6e9; }
.profileDetailSection .mat-form-field-appearance-legacy .mat-form-field-wrapper { padding-bottom: 0.94em;  }


.profileAddSection .button .btn { cursor: pointer; }

.profileAddSection .profileImage{ background: #ffffff; border: 1px solid #ebebeb; box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01); -webkit-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01); margin-bottom: 15px; padding: 15px; width: 240px; float: left; }
.profileAddSection .profileImage .image{ background-color: #e3e3e3; border-radius: 50%; width: 110px; height: 110px; margin: 0px auto 30px; position: relative; }
.profileAddSection .profileImage .image .loaderFile { width: 41px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; }
.profileAddSection .profileImage .image .circleImg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
}
.profileAddSection .profileImage .image .circleImg img{border-radius: 50%;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;}

.profileAddSection .profileImage .image .changeIcon {
    width: 40px;
    height: 40px;
    background-color: #3174bc;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    right: 0;
    z-index: 1;
    font-size: 16px;
    line-height: 40px;
}
.profileAddSection .profileDetailSection .button .btn {
  min-width: 132px;
  text-transform: uppercase;
}
.profileImageForm .mat-horizontal-stepper-header-container { padding-left: 380px; }

.profileImageForm .profileImage {     position: absolute;
  top: 0;
  display: flex;
  display: -webkit-flex;
  width: auto;
  float: none;
  margin-bottom: 0;
  border: 0;
  border-right: 1px solid #ebebeb;
  padding: 18px 15px; align-items: center; }
.profileImageForm .profileImage .image { margin: 0;  min-width: 100px;  width: 100px;
  height: 100px; }
.profileImageForm .profileImage .button { margin-left: 15px; }
.profileImageForm .profileImage .button .btn {
  font-size: 13px;
}
.profileImageForm .profileDetailwithform .profileDetailForm{ margin-left: 0px;}

.file-upload .field-group { margin-bottom: 10px; }
.file-upload .loaderFile{ width: 40px;}
.file-upload .filetag { margin-bottom: 8px; }
.file-upload .downloadFile { margin-bottom: 8px; font-size: 15px; font-weight: 600;}
.file-upload .msg { font-weight: 500; font-size: 15px; }

.profileImageForm .profileDetailSection{ margin-bottom: 0px;}
.profileImageForm .mat-horizontal-stepper-content[aria-expanded=false] { height: auto; overflow: hidden; visibility: visible!important; transform: none!important; }

.notificationPanel .mat-tab-group.mat-primary .mat-ink-bar{ background-color: #0d4e94;}
.notificationPanel .mat-tab-label-active, .notificationPanel .mat-tab-label-active .mat-tab-label-content { color: #0d4e94; opacity: 1; }

.filter-btn { background: #fff; border: 1px solid #fff;border-radius: 5px; cursor: pointer; width: 44px; padding: 5px; height: 44px; margin-left: 20px; display: flex; justify-content: center; align-items: center; }
.filter-btn:hover { background: #cce5ff; border: 1px solid #015c67; }

@media (max-width: 990px) {

.profileImageForm .profileImage {
  display: block;
  padding: 5px 10px;
  text-align: center;
}

.profileImageForm .mat-horizontal-stepper-header-container {
  padding: 19px 15px 19px;
  padding-left: 180px;
}

.profileImageForm .profileImage .image {
  min-width: 60px;
  width: 60px;
  height: 60px;
  margin: auto;
}

.profileImageForm .profileImage .button {
  margin-left: 0;
}

.profileImageForm .profileImage .button .btn {
  font-size: 11px;
  padding: 7px 5px;
  margin-top: 5px;
}
.profileImageForm .profileImage .image .changeIcon {
  width: 30px; min-width: 30px;
  height: 30px;
  font-size: 13px;
  line-height: 30px;
}
}
@media (max-width: 767px) {
  .profileAddSection .profileImage { width: 200px; }
  .profileAddSection .profileImage .button .btn { font-size: 12px; }
  .profileDetailwithform .profileDetailForm { margin-left: 220px; }



.profileImageForm .profileImage {
position: relative;
padding: 15px;
left: 0;
width: 100%;
}

.profileImageForm .mat-horizontal-stepper-header-container {
padding: 15px;
}

.profileImageForm .profileStepForm {
position: relative;
}

}

@media (max-width: 480px) {
  .profileAddSection .profileImage { width: 100%; }
  .profileAddSection .profileImage .button .btn { font-size: 12px; }
  .profileDetailwithform .profileDetailForm { margin-left: 0px; }
}



@media (max-width: 640px) {
.profileStepForm .mat-stepper-label-position-bottom .mat-horizontal-stepper-header { flex-basis: 50%; padding: 13px 5px; }
}


.breadcrumbLink .breadcrumb { background: transparent; width: auto; margin: 0; padding: 0; }
.breadcrumb-item+.breadcrumb-item::before { content: ">"; }
.breadcrumbLink .breadcrumb li.breadcrumb-item { font-weight: 600; font-size: 13px; color: #111; }

.invalid-feedback-Job-Type {
  margin-top: .25rem;
  font-size: .875rem;
  color: #dc3545;
  }

/*** Custom Stepper **/
  .custom-stepper .stepperHeader {
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-top: 0px;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01);
    -webkit-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.01);
    padding: 15px;
    /* flex-wrap: wrap; */
    display: flex;
    display: -webkit-flex;
}

.custom-stepper .stepperHeader ul.stepList {
    display: flex;
    display: -webkit-flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.custom-stepper .stepperHeader ul.stepList li { cursor: pointer;
    text-align: center;
    padding: 13px;    min-width: 25%;     position: relative;
}

.custom-stepper .stepperHeader ul.stepList li .icon {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 2;
    padding: 13px;
    margin: auto;
}

.custom-stepper .stepperHeader ul.stepList .label {
    font-size: 16px;
    font-weight: 600;
    padding-top: 15px;
    position: relative;
}

.custom-stepper .stepperHeader ul.stepList:after {content: "";background: rgba(0,0,0,.12);height: 1px;position: absolute;width: 100%;left: 0;top: 38px;}
.custom-stepper .stepperHeader ul.stepList li.active .label:before {background: #00A651;content: "";width: 8px;height: 8px;display: block;border-radius: 100%;margin: auto;position: absolute;top: 4px;left: 0;right: 0;margin: auto;}

.profileImageForm .custom-stepper .profileImage { position: relative; padding: 0 15px 0 0; flex-basis: 480px; }

.custom-stepper .stepperHeader ul.stepList li:first-child:after,.custom-stepper .stepperHeader ul.stepList li:last-child:after {content: "";position: absolute;left: 0;width: 50%;background: #fff;height: 3px;top: 38px;z-index: 1;}

.custom-stepper .stepperHeader ul.stepList li:last-child:after {
    left: auto;
    right: 0;
}

.custom-stepper .stepperContent {
  display: flex;
  display: -webkit-flex;
}

.custom-stepper .stepperContent .leftForm {
  padding-right: 7.5px;
}

.custom-stepper .stepperContent .resumePreview {
  flex-basis: 36%;
  min-width: 36%;
  padding-left: 7.5px;
}

.custom-stepper .stepperContent .resumePreview .profileDetailSection {
  height: calc(100% - 15px);
}

.custom-stepper .stepperContent .resumePreview pre {
  height: 1928px;
}
.resumefull .custom-stepper .stepperContent .resumePreview {
  position: absolute;
  top: 0;
  width: calc(100% - 30px);
  z-index: 2;
  padding: 0;
  height: 100%;
}

.resumefull .custom-stepper .stepperContent .resumePreview .profileDetailSection {
  margin: 0;
  height: 100%;
}
.resumefull i.full-icon, .resumeshow i.exit-icon{
  display: none;
}

.resumefull i.exit-icon, .resumeshow i.full-icon  {
  display: block;
}
.resumefull .custom-stepper .stepperContent .resumePreview pre {
  height: 2092px;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: none;
  opacity: 0;
}


/*-------------------------------------------------------*/
/* Responsive Css
/*-------------------------------------------------------*/
@media (max-width: 1200px) {
  #main-wrapper #content-wrapper #content{ padding: 35px 15px;}

}
@media (max-width: 990px) {
  #main-wrapper #content-wrapper #content { padding: 35px 0px; }

}
@media (max-width: 767px) {
  .custom-stepper .stepperHeader ul.stepList .label {
    font-size: 14px;
    }
    /*


.profileImageForm .custom-stepper .profileImage {
min-width: 204px;
flex-basis: 204px;
}*/
.cal-month-view .cal-day-number {
  font-size: 0.9em;
  margin-right: 8px;
}
}
@media (max-width: 640px) {
  .wrapper { padding-top: 66px; }
/*
.profileImageForm .custom-stepper .profileImage {
  min-width: auto;
  flex-basis: 204px;
  display: flex;
  padding: 10px;
  margin-bottom: 10px;
}

.custom-stepper .stepperHeader {
  flex-wrap: wrap;
}

.profileImageForm .custom-stepper .profileImage .button {
  display: flex;
  margin-left: 10px;
}

.profileImageForm .custom-stepper .profileImage .button .btn {
  margin: 4px 5px;
}

.custom-stepper .stepperContent {
  flex-wrap: wrap;
  flex-direction: column-reverse;
}

.custom-stepper .stepperContent .resumePreview {
  flex-basis: 100%;
  min-width: 100%;
  padding: 0;
  order: 1;
}

.custom-stepper .stepperContent .leftForm {
  padding: 0;
  order: 0;
}*/
}

@media (max-width: 480px) {

.searchFilter .selectgroup .selectfield { padding-right: 40px; }
.searchFilter .selectgroup > label { white-space: normal; font-size: 13px; }

/*.profileImageForm .custom-stepper .profileImage .button { display: block; }
.profileImageForm .profileImage .image { min-width: 80px; width: 80px; height: 80px; }

.custom-stepper .stepperHeader ul.stepList { flex-wrap: wrap; }
.custom-stepper .stepperHeader ul.stepList:after { display: none; }
.custom-stepper .stepperHeader ul.stepList li { min-width: 50%; margin-bottom: 5px; }
*/
}


@media (max-width: 990px){
  .custom-stepper .stepperContent {
    flex-wrap: wrap;
}

.custom-stepper .stepperContent .resumePreview {
    min-width: 100%;
    flex-basis: 100%;
    padding: 0;
}

.custom-stepper .stepperContent .leftForm {
    padding-right: 0px;
}

.custom-stepper .stepperContent .resumePreview pre {
    max-height: 1928px;
    height: auto;
}

.resumefull .custom-stepper .stepperContent .resumePreview pre {
    max-height: 2300px;
    height: 2300px;
}
}
@media (max-width: 767px) {

    .custom-stepper .stepperHeader ul.stepList .label {
    font-size: 13px;
    }

    .profileImageForm .custom-stepper .profileImage {
    min-width: 204px;
    flex-basis: 204px;padding-left: 0px;
    }
    }
    @media (max-width: 680px) {
      .custom-stepper .stepperHeader{ flex-wrap: wrap;}

    .profileImageForm .custom-stepper .profileImage {
      min-width: auto;
      flex-basis: 380px;
      border-right: 0px;
      display: flex;
      padding: 10px;
      margin-bottom: 10px;
    }


    .profileImageForm .custom-stepper .profileImage .button {
      display: flex;
      margin-left: 10px;
    }

    .profileImageForm .custom-stepper .profileImage .button .btn {
      margin: 4px 5px;
    }
    .profileImageForm .profileDetailSection .form-upload .custom-file label.btnFile {
      font-size: 14px;
  }
}

    @media (max-width: 480px) {
.profileImageForm .custom-stepper .profileImage .button {
display: block;
}
.profileAddSection .profileDetailSection .button .btn {
min-width: 96px;
margin-left: 0px!important;
}
.profileImageForm .profileImage .image {
width: 80px;
height: 80px;
}

.custom-stepper .stepperHeader ul.stepList {
flex-wrap: wrap;
}

.custom-stepper .stepperHeader ul.stepList:after { display: none; }
.custom-stepper .stepperHeader ul.stepList li { min-width: 46%; flex-basis: 46%; margin-bottom: 5px; }
}

.card.profileDetail {
  margin-bottom: 15px;
}

.mat-tooltip {background: rgba(0,0,0,0.8);font-size: 13px; }



/*-------------------------------------------------------*/
/* search Filter Css
/*-------------------------------------------------------*/
.searchField { position: relative; }

/* .searchField input.form-control { appearance: none; -webkit-appearance: none;} */

.searchFilter .form-control.mat-select, .searchField .form-control { border: 1px solid #e0e0e0; border-radius: 5px; display: block; width: 100%; height: 52px; padding: 15px; font-size: 15px; padding-right: 34px; }
.searchField .btn-search { background: transparent; border: 0; position: absolute; right: 0; top: 0; color: #0d4e94; width: 40px; height: 52px; }
.searchField .btn-search i { vertical-align: middle; }

.selectgroup { background: #ffffff; border: 1px solid #e0e0e0; border-radius: 5px; height: 52px; padding: 0px 0px 0px 10px; font-size: 15px; display: flex; display: -webkit-flex; align-items: center; overflow: hidden; }
.selectgroup > label {font-weight: 600; font-size: 14px; white-space: nowrap; margin-right: 10px;}

.mat-select.mat-select-invalid{border-color: red!important;}

.selectgroup .mat-form-field-underline, .selectgroup .mat-form-field-subscript-wrapper { display: none; }
.selectgroup .mat-form-field-appearance-legacy .mat-form-field-wrapper { padding-bottom: 0px; }
.selectgroup .mat-form-field .mat-form-field-infix { padding: 0px; border-top: 0px; }
.selectgroup .mat-select-arrow-wrapper .mat-select-arrow { display: inline-block; width: 12px; height: 12px; border: 2px solid; border-bottom: 0; border-left: 0; border-radius: 1px; -webkit-transform: rotate(135deg); -moz-transform: rotate(135deg); -ms-transform: rotate(135deg); -o-transform: rotate(135deg); transform: rotate(135deg); }
.selectgroup .mat-select-arrow-wrapper { box-shadow: none; background: #e8e8e8; height: 52px; min-width: 39px; text-align: center; }
.selectgroup .mat-select-value { vertical-align: middle;}

.searchFilter .form-control.mat-select {
  padding: 12px;
}

.searchFilter .control-label {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 15px;
}
.searchFilter .button .btn { height: 52px; }

.add-btn{    width: 44px;
    height: 44px;
    background: #0d4e94;
    display: inline-block;
    border-radius: 100%;
    padding: 8px;
    line-height: 15px;}
.download-btn{
    width: 36px;
    height: 36px;
    background: #f6f6f6;
    border: 1px solid #c5c5c5;
    display: inline-block;
    border-radius: 100%;
    padding: 6px;
    line-height: 15px;}

.searchField input {
    padding-right: 55px;
}

.searchFilter .control-label {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 15px;
}




/*-------------------------------------------------------*/
/*  Content Css
/*-------------------------------------------------------*/
.reportContent {
  position: relative;
}

.maintitle{ width: 100%; padding-right: 130px; }
.maintitle h1{ font-size: 1.2rem; font-weight: 300; }
.maintitle.minh-44{ min-height: 44px;}

.addReport a { width: 44px; height: 44px;
background: #0d4e94;
display: block;
border-radius: 100%;
padding: 8px;
line-height: 15px;
}


.custom-tab {
  background: #fff;
  border: 1px solid #ddd;
}
.custom-tab .tab-content {
    padding: 15px;
}
.custom-tab .nav-tabs {
  display: -webkit-box;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.custom-tab .nav-tabs li {
  background: #f4f6f8;
  border-right: 1px solid #ddd;
  flex-basis: 25%;
  position: relative;
  cursor: pointer;margin: 0;
}

.custom-tab .nav-tabs li a {
  padding: 15px 5px;
  font-size: 15px;
  text-align: center; display: block;
  color: #111;
  border: 0px;
}

.custom-tab .nav-tabs li a.active {
  border-bottom: 1px solid #fff;
  margin-bottom: -1px;
}
.custom-tab .nav-tabs li a.active:after {
  content: "";
  background: #084e94;
  height: 2px;
  width: 100%;
  position: absolute;
  left: 0;
  top: -1px;
}
.custom-tab .tab-content {
  padding: 15px;
}

.tabContent {
  position: relative;
}

.reportContent .mat-tab-header, .tabContent .mat-tab-header{
  position: absolute!important;
  right: 0;
  top: 0;
}
.mat-tab-label {
  background: #fff;
  border-radius: 5px;
}
.mat-tab-label {
  background: #fff;
  border-radius: 5px;
  opacity: 1!important;
  width: 44px;
  padding: 0 !important;
  height: 44px !important;
  min-width: 44px !important;
  margin-left: 20px;
}
.matTabs img.mattabIcon{width: 26px !important;}
.mat-tab-header, .mat-tab-nav-bar{border:0 !important;}
.mat-ink-bar{display: none;}
.mat-tab-body-content{ overflow: hidden; padding: 0 0px;}
/*-------------------------------------------------------*/
/* Multi Step Css
/*-------------------------------------------------------*/
ul.multiple-steps { width: 100%;padding: 0;margin: 2em auto; display: flex; display: -webkit-flex; flex-wrap: wrap; }
ul.multiple-steps li {position: relative;padding: 0;display: inline-block; margin: 0px 0px 10px 0;border: 2px solid #d7d7d7;}
ul.multiple-steps li a{ background: #f3f3f3; display: inline-block; font-size: 15px; font-weight: 600; position: relative; padding: 0.78em .2em 0.78em 2.5em; color: #6c6c6c; border-color: #f3f3f3; padding-left: 2em; }
ul.multiple-steps li::after, ul.multiple-steps li::after { display: inline-block; position: absolute; top: -3px; left: 100%; content: ''; height: 0; width: 0; border: 26px solid transparent; border-right-width: 0; border-left-width: 25px; z-index: 1; border-left-color: #d7d7d7; margin: 0; border-top-width: 26px; border-bottom-width: 25px; }
ul.multiple-steps li a:after{ content: ''; position: absolute; top: -1px; left: 100%; height: 0; width: 0; border: 24px solid transparent; border-right-width: 0; border-left-width: 24px; z-index: 2; border-left-color: #f3f3f3; transform: translateX(-2px); }
ul.multiple-steps li.current:after { border-left-color: #0d4e94; }
ul.multiple-steps li.current { border-color: #0d4e94; }
ul.multiple-steps li.current a { color: #0d4e94; }
ul.multiple-steps li a img, ul.arrow-steps li a img { height: 23px; margin: 0 10px 0 0px; width:20px }
ul.multiple-steps li a img.active, ul.multiple-steps li.current a img,ul.arrow-steps li a img.active, ul.arrow-steps li.current a img { display: none; }
ul.multiple-steps li:first-child a { padding-left: 10px; }
ul.multiple-steps li.current a img.active,ul.arrow-steps li.current a img.active{display: inline-block;}

ul.arrow-steps { width: 100%; padding: 0; margin: 1em auto; display: flex; display: -webkit-flex; }
ul.arrow-steps li {position: relative; display: inline-block; margin: 0px 0px 10px 0; width: 20%; }
ul.arrow-steps li a{ background: #f3f3f3; border: 1px solid #d7d7d7; border-left: 0px; border-right: 0; display: flex; align-items: center; font-size: 15px; font-weight: 600; position: relative; padding: 0.78em .2em 0.78em 2em; color: #6c6c6c; height: 46px; }
ul.arrow-steps li a:after{ content: ''; background: #f3f3f3; border-top: 1px solid #ccc; border-right: 1px solid #ccc; width: 32px; height: 32px; position: absolute; right: -6px; top: 2px; z-index: 7;
-webkit-transform: translate(10px,4px) rotate(45deg);
-moz-transform: translate(10px,4px) rotate(45deg);
-ms-transform: translate(10px,4px) rotate(45deg);
-o-transform: translate(10px,4px) rotate(20deg);
transform: translate(10px,4px) rotate(45deg);
}
ul.arrow-steps li a span{ position: relative; z-index: 8;}
ul.arrow-steps li:first-child a {
border-left: 1px solid #d7d7d7;
padding-left: 10px;
}

ul.arrow-steps li.current a {
  color: #fff;
  background: #0d4e94;
  border-color:#0d4e94;
}

ul.arrow-steps li.current a:after {
background: #0d4e94;
border-color:#0d4e94;
}

@media (max-width: 1440px) {
  ul.arrow-steps li a { font-size: 13px; padding: 0.85em .2em 0.85em 2em; }
}

@media (max-width: 990px) {
  ul.arrow-steps { flex-wrap: wrap; }
  ul.arrow-steps li:last-child a {
    border-left: 1px solid #d7d7d7;
}
}
@media (max-width: 700px) {
  ul.arrow-steps li{ width: 50%; }
  ul.arrow-steps li:nth-child(3) a {  border-left: 1px solid #d7d7d7; }
}
@media (max-width: 410px) {
  ul.arrow-steps li{ width: 100%; }
  ul.arrow-steps li a {  border-left: 1px solid #d7d7d7; }
}
.animationImages{ background: #ffffff; border: 1px solid #ebebeb; text-align: center;}


/* ----- */

.modalTitle button.mat-button {
  margin-left: auto !important;
  padding: 0 !important;
  min-width: 32px !important;
  height: 22px !important;
  background: 0 0 !important;
}


.textFormat{
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}



.profileDetail .action button {    padding: 5px 8px;
  width: 34px;
  height: 34px;
  margin-left: 15px}

  .profileDetail .profileimage{margin-right: 30px;}

  .profileDetail .profileimage .image {
      border: 1px solid #dee2e6;
      background: #fbfbfb;
      width: 151px;height: 151px;
      border-radius: 100%;overflow: hidden;

      margin-bottom: 15px;display: flex; display: -webkit-flex; align-items: center;
  } .profileDetail .profileimage .image img{max-width: 100%;}

  .profileDetail .profileshortDetail {
      margin-top: 15px;
  }

  .profileDetail .profileshortDetail h4 {
      font-weight: 600;
      margin-bottom: 14px;
      font-size: 21px;
      text-transform: capitalize;
  }

/* Change */
  .notificationPanel .mat-tab-labels .mat-tab-label {
    height: 48px!important;
    padding: 0 24px!important;
    cursor: pointer;
    box-sizing: border-box;
    opacity: .6;
    min-width: 50%!important;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
    margin: 0px!important;
}
.notificationPanel .mat-tab-header {
    border-bottom: 1px solid rgba(0,0,0,.12)!important;
}


/* .Joblist Modal */
.searchFilter .selectgroup .mat-select-arrow-wrapper .mat-select-arrow{
    margin: 16px auto 0;
}



.publ_sec.checkboxgroup .custom-checkbox {
  padding-left: 0px;
}

.publ_sec.checkboxgroup .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {left: 4px;top: 0px;}
.publ_sec.checkboxgroup .custom-checkbox .custom-control-label{
line-height: 1.2rem;
}

.select-label{ font-size: 14px; margin-bottom: 3px; font-weight: 500;}


a.disabled {
  color: gray;
  cursor: not-allowed;
  text-decoration: underline;
}

/* //Akhilesh : 20200910, resume  badge css */
.badgetag{
  color: #fff!important;
  width: 250px;
  word-wrap: break-word;
   background-color: #1dc9b7!important;
  width: 250px;
  border-radius: .25rem;
}

/* 20200919 Akhilesh, Jira no vs-50 */
.word-wrap{
  word-break: break-all;
}


.Img_size{
  height:30px !important;
   width:30px !important;
}
 /* 20200919 Akhilesh */
.chart-size{
    /* width:90px; */
}

.searchbox{
  background-color: lightgrey !important;  padding-top: 12px !important;
}

/*** Table Primary Css **/
.table-primary {
  background: #fff;
  border-collapse: separate;
  border-spacing: 0px;
}
.table-primary th, .table-primary td {
  font-weight: 500;
  vertical-align: middle;
}
.table-primary th, .table-primary thead th {
  background: #fff;
  border: 0px;
  font-size: 13px;
  text-transform: capitalize;
  color: #111111;
  font-weight: 500;
  vertical-align: middle;
  line-height: 1rem;
  padding: 5px;
  height: 40px;
  border-bottom: 1px solid #dee2e6;
}
.table-primary td, .table-primary tbody td {
  border: 0px;
    background: #fff;
    border: 0;
    padding: 5px 10px;
    height: 44px;
    border-top: 1px solid #dee2e6;
    font-size: 12px;
    line-height: 1rem;
    font-weight: 400;
    vertical-align: middle;
}

.table-primary td:first-child {
  border-radius: 0px;
}

.table-primary td:last-child {
  border-radius: 0px;
}

.table-primary tbody tr {}


.searchFilter-v2 { display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;   height: 46px; margin-bottom: 1rem;  }

.searchFilter-v2 .material-input .mat-form-field-wrapper { padding-bottom: 0; }
.searchFilter-v2 .material-input .mat-form-field-flex {  background: #fff;    padding-bottom: 0.5rem; }
.searchFilter-v2 .material-input .mat-form-field-underline { display: none; }
.searchFilter-v2 .material-input .mat-form-field-infix {  border: 0; padding: 0.4rem 0 0rem; }

.searchFilter-v2  .material-input { flex-grow: 1; border-right: 1px solid #e6e6e6; padding: 0.2rem 0; font-size: 14px; min-width:100px; }
.searchFilter-v2  .field-date .mat-form-field-wrapper .mat-form-field-flex { border: 0px; border-right: 1px solid #e6e6e6; }
.searchFilter-v2 .button { display: flex; }
.border-rd0 { border-radius: 0px!important; }
.searchFilter-v2 .material-input .mat-form-field-flex .mat-form-field-label { padding-top: 6px; }
.searchFilter-v2  .field-date .mat-form-field-wrapper .mat-form-field-flex { padding-top: 11px; padding-bottom: 32px; }
.searchFilter-v2 .button .btn { font-weight: 400; font-size: 15px; }


.searchFilter-v2 .field-date .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper{ position: absolute; top: 92%; }

/* Mat select Control */

.mat-select-control .mat-form-field-wrapper {
  padding: 0.483rem 0.3rem 0.483rem 0.75rem;
  border: 1px solid #ced4da;
  overflow: hidden;
  height: calc(2.25rem + 2px);
}
.mat-select-control .mat-form-field-wrapper .mat-form-field-flex {
  background: transparent;
  padding: 0;
  border-radius: 0px;
  display: flex;
  align-items: center;
}
.mat-select-control .mat-form-field-wrapper .mat-form-field-label-wrapper {
  top: -.55em;
}
.mat-select-control.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  display: none;
}
.mat-select-control .mat-form-field-wrapper .mat-form-field-infix {
  border: 0px;
  padding: 0;
  margin: 0px;
  vertical-align: middle;
  width: auto;
}
.mat-select-control .mat-form-field-wrapper .mat-select-trigger {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.mat-select-control .mat-form-field-wrapper .mat-select-trigger .mat-select-value {
  max-width: 100%;
  font-size: 13px;
  margin-right: 5px;
}
.mat-select-control .mat-form-field-wrapper .mat-select-trigger .mat-select-arrow-wrapper {
  margin-left: auto;
  transform: none;
}
.mat-select-control .mat-form-field-wrapper .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
  width: 9px;
  height: 9px;
  border: 1px solid;
  border-bottom: 0;
  border-left: 0;
  border-radius: 1px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  border-color: #000;
  margin-top: -4px;
}
.mat-select-control .mat-form-field-underline, .mat-select-control .mat-form-field-subscript-wrapper {
  display: none;
}
.mat-select-control.mat-select-line .mat-form-field-wrapper {
  border: 0px;
  padding-left: 0px;
}
.mat-select-control.mat-select-lg .mat-form-field-wrapper {
  padding: 1.1rem 0.3rem 1.1rem 0.75rem;
}

/****** Tag input ******/
.searchField  tag-input.form-control, .searchFilter-v2  tag-input.field-tag {padding: 0px;min-height: 52px;height: auto; border-right: 1px solid #e6e6e6;}
tag-input.field-tag .ng2-tag-input { margin: 0px; padding: 6px 0; border: 0px!important; }
tag-input.field-tag .ng2-tag-input__text-input { width: 224px; font-size: 12px;
  height: 100%;}
tag-input.field-tag .ng2-tag-input__text-input[aria-label="+ Tag"] { width: 72px; }
tag-input.field-tag delete-icon svg {height: 12px!important;vertical-align: 3px!Important;}
tag-input.field-tag delete-icon:hover{    -webkit-transform: scale(1.5) translateY(0px); transform: scale(1.5) translateY(0px);}
tag-input.field-tag .tag-wrapper { line-height: 1rem;
  align-items: center;
  font-size: 10px; }
tag-input.field-tag .ng2-tags-container { max-width: 426px;
  position: relative;
  flex-wrap: nowrap!Important;
  align-items: center;
  padding-left: 8px;
  padding-top: 9px;;}
 tag-input.field-tag .ng2-tags-container tag {
  height: auto;
}

.circle-Img{
  height: 75px;
  width: 75px;
  border-radius: 50%;
}

.loaderFile img {
  height: 50px;
  width: 50px;
  display: block;
}

.upload-image { display: flex;  display: -webkit-flex; align-items: center; }
.upload-image .circle-Img { width: 80px; height: 80px; border-radius: 50%; position: relative; min-width: 100px; }
.upload-image  .changeIcon { width: 34px; height: 34px; background-color: #3174bc; color: #fff; border-radius: 50%; text-align: center; cursor: pointer; position: absolute; bottom: 0px; right: 0; z-index: 1; font-size: 16px; line-height: 34px; }
.upload-image .circle-Img img { border-radius: 50%; width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; -o-object-position: center; object-position: center; }
.upload-image .button {  padding-left: 1rem; }
.upload-image .button .btn { font-size: 12px; }
.upload-image .circle-Img .loaderFile { width: 40px; height: 40px; position: absolute; left: 0; right: 0; margin: auto; top: 0px; bottom: 0; }


/********** FOrm Tag Css ***********/

.form-tag {
  max-width: 290px;
  border-right: 1px solid #e6e6e6;
  padding-right: 10px;
  min-width: 209px;
}

.form-tag input {
  font-size: 13px!important;
  width: 100%;
  padding: 0;
}

.form-tag tag-input-form {
  width: 100%;
}
.form-tag .ng2-tag-input__text-input {
  width: 100%;
  padding: 0;
}

.form-tag .ng2-tags-container {
  flex-wrap: nowrap!important;
}

.informationModal .modal-dialog { pointer-events: auto; }
.informationModal .modal-dialog button.close { color: #fff;  opacity: 0.7; }
